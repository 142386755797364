import { Accordion, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Class } from '../../types/Class';
import { Student } from '../../types/Student';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function ExamFeesTable(data: any) {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

    const classes = data.data.items as Class[];
    const termId  = data.data.id;

  return (
    <Accordion defaultActiveKey={classes.map(c => c.name)} alwaysOpen>
          {classes.map((item: Class) => (
              <Accordion.Item key={item.id} eventKey={item.name}>
                  <Accordion.Header>{item.name} - {item.yearlyFee}</Accordion.Header>
                  <Accordion.Body>
                    {
                    item.students.length > 0 ? 
                    <Table striped hover responsive>
                      <thead>
                          <tr>
                              <th>Name</th>
                              <th>Payments</th>
                              <th></th>
                          </tr>
                      </thead>
                      <tbody>
                          {item.students.map((student: Student) => {

                            const thisYearsPayments = student.examYearFeePayments;
                            const thisTermPayments = thisYearsPayments.filter((payment: any) => payment.termId === termId);

                            const totalPaidThisTerm = thisTermPayments.reduce((total, payment) => total + payment.amountPaid, 0);
                            const totalPaidThisYear = thisYearsPayments.reduce((total, payment) => total + payment.amountPaid, 0);

                            const paid = totalPaidThisYear >= item.yearlyFee;

                            return (
                                <tr key={student.id} className={paid ? 'success' : ''}>
                                  <td>{student.name}</td>
                                  <td>
                                      {totalPaidThisTerm}
                                  </td>
                                  <td>
                                    {
                                        canEdit(systemUser) &&
                                        <Link to={`/ExamFee/${data.termYearId}/${termId}/${student.id}`} className="button">
                                            <Button>
                                                Edit
                                            </Button>
                                        </Link>
                                    }
                                      
                                  </td>
                              </tr>
                              )})}
                      </tbody>
                    </Table> : <div>No exam fees to show</div>}
                  </Accordion.Body>
              </Accordion.Item>
              ))}
        </Accordion>
  )
}

export default ExamFeesTable;