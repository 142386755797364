import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { getReport } from '../../functions/fetchEntities';
import Loading from '../Loading';
import ReportTabs from './ReportTabs';
import { ReportYear } from '../../types/ReportYear';

function ReportPage() {

const [reports, setReports] = useState([] as ReportYear[]);
const [selectedYear, setSelectedYear] = useState({} as ReportYear);

  useEffect(() => {

    const getProperties = async () => {
        const reports = await getReport();

        setReports(reports);
        setSelectedYear(reports[0]);
    }

    getProperties();

    }, []);


    const handleTermYearChange = (event: React.MouseEvent<HTMLElement>) => {
        const yearPeriod = (event.target as HTMLElement).innerText;

        const selectedYear = reports.find((reportYear) => reportYear.periodName === yearPeriod);
        setSelectedYear(selectedYear as ReportYear);
    }
  
    return (
        <>
            {reports.length > 0 ?
            <div>
            <div className="top-buttons">
                <div className='select-year-btn'>
                    <Dropdown className="navitem">
                        <Dropdown.Toggle variant="success" id="selectYear">
                            {selectedYear.periodName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {reports.map((reportYear) => (
                                <Dropdown.Item onClick={handleTermYearChange} key={reportYear.periodName}>{reportYear.periodName} </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {selectedYear.termPeriods && <ReportTabs props={selectedYear} />}
        </div> : <Loading />}
        </>
  )
}

export default ReportPage;