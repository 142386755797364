import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';
import { getCurrentTerm } from '../../helpers/TermTimeHelper';
import { UniformSalesYear } from '../../types/UniformSalesYear';
import UnformSalesTable from './UnformSalesTable';
import { InventoryItem } from '../../types/InventoryItem';

function UniformSalesTabs(props:any) {

    var uniformSalesYear = props.selectedYear as UniformSalesYear;
    var uniformTypes = props.uniformTypes as InventoryItem[];

  return (
    <Tabs defaultActiveKey={getCurrentTerm(uniformSalesYear.termPeriods)}>
        {
            uniformSalesYear.termPeriods && uniformSalesYear.termPeriods.map((termPeriod) => (
                <Tab eventKey={termPeriod.termName} title={termPeriod.termName} key={termPeriod.termName}>
                    <h1 className='centered'>{getDisplayDate(termPeriod.startDate)} - {getDisplayDate(termPeriod.endDate)}</h1>
                    <UnformSalesTable data={termPeriod.items} uniformTypes={uniformTypes} />
                </Tab>
            ))
        }
    </Tabs>
  )
}


export default UniformSalesTabs;