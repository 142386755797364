import { LibraryBook } from "../types/LibraryBook";
import { LibraryBookRentalItem } from "../types/LibraryBookRentalYear";

export const noOfBooksAvailable = (book: LibraryBook, rentals: LibraryBookRentalItem[]) => {

    const numberRented = rentals.filter(x => x.libraryBookId === book.id).length;

    const numberAvailable = book.quantity - numberRented;


    return  numberAvailable;
}


export const isBookAvailable = (book: LibraryBook, rentals: LibraryBookRentalItem[]) => {

    return  noOfBooksAvailable(book, rentals) > 0;
}