import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getClass, getEnvironmentVariable, postClass } from '../../functions/fetchEntities';
import Loading from '../Loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { ClassRequest } from '../../types/RequestModels/ClassRequest';
import { ClassMapper } from '../../mappers/ClassMapper';

function EditClass() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [validated, setValidated] = useState(false);
const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [selectedClass, setSelectedClass] = useState({} as ClassRequest);
const [JHS3ID, setJHS3Id] = useState("");
const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        getClass(parsedId)
            .then((data) => setSelectedClass(ClassMapper.mapClassToClassRequest(data)));

        getEnvironmentVariable("JHS3_ID")
            .then((data) => setJHS3Id(data));
    }
        
    }, [parsedId]);

 
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setSelectedClass({...selectedClass, name: name});
        setHasBeenEdited(true);
    }

    const handleTermFeeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const termFee = parseFloat(event.target.value);
        setSelectedClass({...selectedClass, termFee: termFee});
        setHasBeenEdited(true);
    }

    const handlePinkAndCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
        const pinkAndCheckedPrice = parseFloat(event.target.value);
        setSelectedClass({...selectedClass, pinkAndCheckUniformPrice: pinkAndCheckedPrice});
        setHasBeenEdited(true);
    }

    const handleWednesdayChange = (event: ChangeEvent<HTMLInputElement>) => {
        const wednesdayUniformPrice = parseFloat(event.target.value);
        setSelectedClass({...selectedClass, wednesdayUniformPrice: wednesdayUniformPrice});
        setHasBeenEdited(true);
    }

    const handleFridayChange = (event: ChangeEvent<HTMLInputElement>) => {
        const fridayUniformPrice = parseFloat(event.target.value);
        setSelectedClass({...selectedClass, fridayUniformPrice: fridayUniformPrice});
        setHasBeenEdited(true);
    }

    const handleExamYearFeeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const examYearPrice = parseFloat(event.target.value);
        setSelectedClass({...selectedClass, yearlyFee: examYearPrice});
        setHasBeenEdited(true);
    }


    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            if (hasBeenEdited) {
                selectedClass.updatedBy = systemUser.displayName;
                await postClass(selectedClass);
            }
            navigate('/Classes', {replace: true});
        }
    
        setValidated(true);
      };

  
    return (
        <>
             {parsedId === "new" || selectedClass.id !== undefined ?
             <div>
             <h1>Edit Class</h1>
     
             <div className='form-background'>
             <div className='form-container'>
                 <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>


                 <Form.Group className="mb-3" controlId="formName">
                     <Form.Label>Name</Form.Label>
                     <Form.Control
                     id="edit-name" 
                     type="text" 
                     placeholder="Class Name" 
                     onChange={handleNameChange} 
                     value={selectedClass.name} 
                     required
                     />
                 </Form.Group>

                 <Form.Group className="mb-3" controlId="formTermFee">
                     <Form.Label>Term Fee</Form.Label>
                     <Form.Control 
                     id="edit-term-fee" 
                     type="number"
                     step="any" 
                     placeholder="400" 
                     onChange={handleTermFeeChange} 
                     value={selectedClass.termFee} 
                     min="0"
                     required
                     />
                 </Form.Group>

                 <Form.Group className="mb-3" controlId="formPinkAndCheck">
                     <Form.Label>Pink and Check Uniform Price</Form.Label>
                     <Form.Control 
                     id="edit-pink-and-check-unifrom" 
                     type="number"
                     step="any" 
                     placeholder="105" 
                     onChange={handlePinkAndCheckChange} 
                     value={selectedClass.pinkAndCheckUniformPrice} 
                     min="0.1"
                     required
                     />
                 </Form.Group>

                 <Form.Group className="mb-3" controlId="formWednesdayUniform">
                     <Form.Label>Wednesday Uniform Price</Form.Label>
                     <Form.Control 
                     id="edit-wednesday-unifrom" 
                     type="number"
                     step="any" 
                     placeholder="105" 
                     onChange={handleWednesdayChange} 
                     value={selectedClass.wednesdayUniformPrice} 
                     min="0.1"
                     required
                     />
                 </Form.Group>


                 <Form.Group className="mb-3" controlId="formFridayUniform">
                     <Form.Label>Friday Uniform Price</Form.Label>
                     <Form.Control 
                     id="edit-friday-unifrom" 
                     type="number"
                     step="any" 
                     placeholder="105" 
                     onChange={handleFridayChange} 
                     value={selectedClass.fridayUniformPrice} 
                     min="0.1"
                     required
                     />
                 </Form.Group>

                 {
                 selectedClass.id === JHS3ID && 
                 <Form.Group className="mb-3" controlId="formYearlyExamFee">
                     <Form.Label>Yearly Exam Fee</Form.Label>
                     <Form.Control 
                     id="edit-exam-fee" 
                     type="number"
                     step="any" 
                     placeholder="105" 
                     onChange={handleExamYearFeeChange} 
                     value={selectedClass.yearlyFee} 
                     min="0.1"
                     required
                     />
                 </Form.Group>
                 }

                 <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                     Save
                 </Button>
                 </Form>
             </div>
             </div>
          </div> : <Loading />}
        </>
  )
}

export default EditClass;