import React from 'react'
import { Accordion, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getBooleanString } from '../../helpers/BooleanHelper';
import { getDisplayDate } from '../../helpers/DateHelper';
import { UniformSalesItem } from '../../types/UniformSalesYear';
import { InventoryItem } from '../../types/InventoryItem';
import { createButtonTestId } from '../../helpers/StringHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function UnformSalesTable(props: any) {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

    const sales = props.data as UniformSalesItem[];
    const uniformTypes = props.uniformTypes as InventoryItem[];

  return (
    <>
        <div>
            <Accordion defaultActiveKey={uniformTypes.map(u => u.name)} alwaysOpen>
            {uniformTypes.map((uniformItemType: InventoryItem) => {
                
                const uniformSales = sales.filter(s => s.inventoryItem.id === uniformItemType.id);
                
                return (
                    <Accordion.Item eventKey={uniformItemType.name}>
                        <Accordion.Header className='uniform-table-row'>{uniformItemType.name}</Accordion.Header>
                        <Accordion.Body>
                            {uniformSales.length > 0 ?
                                <Table striped hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Student</th>
                                            <th>Class</th>
                                            <th>Quantity</th>
                                            <th>Paid in full</th>
                                            <th>Received</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {uniformSales.map((item: UniformSalesItem) => (
                                        <tr key={item.id}>
                                            <td>{getDisplayDate(item.date)}</td>
                                            <td>{item.student.name}</td>
                                            <td>{item.student.class?.name}</td>
                                            <td>{item.quantity}</td>
                                            <td>{getBooleanString(item.paidInFull)}</td>
                                            <td>{getBooleanString(item.received)}</td>
                                            <td>
                                                {
                                                    canEdit(systemUser) &&
                                                    <Link to={`/UniformSale/${item.id}`} className="button">
                                                        <Button id={createButtonTestId(`${item.student.name}_${uniformItemType.name}_${getDisplayDate(item.date)}`)}>
                                                            Edit
                                                        </Button>
                                                    </Link>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            : (
                                <tr className='no-class-row'>
                                    <td colSpan={7}>No uniform sales</td>
                                </tr>
                            )
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                )
            })}
            </Accordion>
        </div>
    </>
  )
}

export default UnformSalesTable;