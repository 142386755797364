import React, { useEffect, useState } from 'react'
import { Accordion, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getExpenses, getExpenseTypes} from '../../functions/fetchEntities';
import { ExpenseType } from '../../types/ExpenseType';
import { ExpenseItem } from '../../types/ExpenseItem';
import { getDisplayDate } from '../../helpers/DateHelper';
import Loading from '../Loading';
import { createButtonTestId, createRowTestId } from '../../helpers/StringHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function Expenses() {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

  const [expenseTypes, setExpenseTypes] = useState<ExpenseType[]>([]);
  const [expenseItems, setExpenseItems] = useState<ExpenseItem[]>([]);

  useEffect(() => {

    getExpenseTypes().then((data) => {
        setExpenseTypes(data);
    })

    getExpenses().then((data) => {
        setExpenseItems(data);
        }
    )

  }, [])


  const notAllowedToEdit = ["Stationery"];

  return (
  <>
      {
        expenseItems ?
        <div>
        {
            canEdit(systemUser) &&
            <div className='add-new-class-btn'>
                <Link className="navitem" to="/ExpenseType/new">
                    <Button variant="primary" className="mb-3">
                        Add Expense Type
                    </Button>
                </Link>
            </div>
        }
                        
        <div className='page-content'>
            <Table striped hover responsive>
            <thead>
                <tr>
                    <th>Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {expenseTypes.map((item: ExpenseType) => (
                    <tr id={createRowTestId(item.name)} className='expense-type-table-row' key={item.id}>
                        <td className='item-name'>{item.name}</td>
                        <td>
                            {notAllowedToEdit.includes(item.name) ? null :
                                
                                <Link to={`/ExpenseType/${item.id}`} className="button">
                                    {
                                        canEdit(systemUser) &&
                                        <Button id={createButtonTestId(item.name)}>
                                            Edit
                                        </Button>
                                    }
                                </Link>
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
            </Table>
        </div>

        {
            canEdit(systemUser) &&
            <div className='add-new-class-btn'>
                <Link className="navitem" to="/Expense/new">
                    <Button variant="primary" className="mb-3">
                        Add Expense
                    </Button>
                </Link>
            </div>
        }

        <Accordion>
            {expenseTypes.map((itemType: ExpenseType) => {
                
                const relatedItems = expenseItems.filter(i => i.expenseTypeId === itemType.id);
                
                return (
                    <Accordion.Item eventKey={itemType.name}>
                         <Accordion.Header>{itemType.name}</Accordion.Header>
                         <Accordion.Body>
                         {relatedItems.length > 0 ? 
                            <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Amount</th>
                                    {/* <th>Total</th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {relatedItems.map((item: ExpenseItem) => (
                                    <tr id={createRowTestId(item.description)} key={item.id}>
                                        <td className='item-date'>{getDisplayDate(item.date)}</td>
                                        <td className='item-description'>{item.description}</td>
                                        <td className='item-quantity'>{item.quantity}</td>
                                        <td className='item-amount'>{item.amount}</td>
                                        {/* <td className='item-total'>{item.total}</td> */}
                                        <td>
                                            {
                                                canEdit(systemUser) &&
                                                <Link to={`/Expense/${item.id}`} className="button">
                                                    <Button id={createButtonTestId(item.description)}>
                                                        Edit
                                                    </Button>
                                                </Link>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        :
                        <p>No expenses</p> 
                        }
                         </Accordion.Body>
                    </Accordion.Item>
                )
            })}
        </Accordion>
      </div> : <Loading />
      }
  </>
  )
}

export default Expenses;