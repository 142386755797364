import { StudentRequest } from "../types/RequestModels/StudentRequest";

export const StudentMapper = {
    mapStudentRequestToStudent: (studentRequest: StudentRequest) => {
        return {

            id: studentRequest.id,
            name: studentRequest.name,
            classId: studentRequest.classId,
            dob: studentRequest.dob,
            scholarshipType: studentRequest.scholarshipType,
            dateLeft: studentRequest.dateLeft,
            class: studentRequest.class,
            schoolTermFeePayments: studentRequest.schoolTermFeePayments,
            examYearFeePayments: studentRequest.examYearFeePayments,
            hasPaidNewAdmissionFormFee: studentRequest.hasPaidNewAdmissionFormFee,
            updatedBy: studentRequest.updatedBy,
            parents: studentRequest.parents,
        };
    },
    mapStudentToStudentRequest: (studentToMap: any) => {

        return {
            id: studentToMap.id,
            name: studentToMap.name,
            classId: studentToMap.classId,
            dob: studentToMap.dob,
            scholarshipType: studentToMap.scholarshipType,
            dateLeft: studentToMap.dateLeft,
            class: studentToMap.class,
            schoolTermFeePayments: studentToMap.schoolTermFeePayments,
            examYearFeePayments: studentToMap.examYearFeePayments,
            hasPaidNewAdmissionFormFee: studentToMap.hasPaidNewAdmissionFormFee,
            updatedBy: studentToMap.updatedBy,
            parents: studentToMap.parents,
        };
    }
};

