import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';
import { getCurrentTerm } from '../../helpers/TermTimeHelper';
import { BookSalesYear } from '../../types/BookSalesYear';
import BookSalesTable from './BookSalesTable';

function BookSalesTabs(props:any) {

    var bookSalesYear = props.props as BookSalesYear;

  return (
    <Tabs defaultActiveKey={getCurrentTerm(bookSalesYear.termPeriods)}>
        {
            bookSalesYear.termPeriods && bookSalesYear.termPeriods.map((termPeriod) => (
                <Tab eventKey={termPeriod.termName} title={termPeriod.termName} key={termPeriod.termName}>
                    <h1 className='centered'>{getDisplayDate(termPeriod.startDate)} - {getDisplayDate(termPeriod.endDate)}</h1>
                    <BookSalesTable data={termPeriod.items} />
                </Tab>
            ))
        }
    </Tabs>
  )
}


export default BookSalesTabs;