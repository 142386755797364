import { LogLevel } from "@azure/msal-browser";

export const redirectUri = process.env.REACT_APP_APP_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;
const tenantID = process.env.REACT_APP_TENANT_ID;

const authorityUri = `https://login.microsoftonline.com/${tenantID}`;

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: authorityUri,
        redirectUri: redirectUri,
        postLogoutRedirectUri: redirectUri
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
      },
      system: {
        loggerOptions: {
          loggerCallback: (level:string, message:string, containsPii:string) => {
          },
          logLevel: LogLevel.Info
        }
      }
};
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.ReadWrite.All"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphUsersEndpoint: "https://graph.microsoft.com/v1.0/users",
    graphInvitationsEndpoint: "https://graph.microsoft.com/v1.0/invitations",
    graphRoleAssignmentsEndpoint: "https://graph.microsoft.com/v1.0/roleManagement/directory/roleAssignments",
    graphDirectoryWriterPermissionID: "9360feb5-f418-4baa-8175-e2a00bac4301"
  };