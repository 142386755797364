import React from 'react'
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getDisplayDate } from '../../helpers/DateHelper';
import { FeedingSaleItem } from '../../types/FeedingSaleYear';
import { createButtonTestId, createRowTestId } from '../../helpers/StringHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function FeedingSalesTable(data: any) {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

  return (
    <Table striped hover responsive>
    <thead>
        <tr>
            <th>Date</th>
            <th>Number of Students</th>
            <th>Number of Students Who Paid</th>
            <th>Total Collected</th>
            <th>Student Who Haven't Paid</th>
            <th>Expenses</th>
            <th>Total Profit</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        {data.data.length > 0 ? data.data.map((item: FeedingSaleItem) => (
            <tr id={createRowTestId(`${getDisplayDate(item.date)} ${item.numberOfStudents} ${item.totalCollected}`)} key={item.id}>
                <td className='item-date'>{getDisplayDate(item.date)}</td>
                <td className='item-students'>{item.numberOfStudents}</td>
                <td className='item-students'>{item.numberOfStudentsPaid}</td>
                <td className='item-total'>{item.totalCollected}</td>
                <td className='item-not-paid'>{item.studentsWhoHaventPaid}</td>
                <td>
                    <ul className='no-list-style' >
                        {item.feedingExpenses.map((expense) => (
                            <li key={expense.id}>{expense.reason} - {expense.amount}</li>
                        ))}
                    </ul>
                </td>
                <td className='item-total'>{item.revenue}</td>
                <td>
                    {
                        canEdit(systemUser) &&
                        <Link to={`/FeedingSale/${item.id}`} className="button">
                            <Button id={createButtonTestId(`${getDisplayDate(item.date)} ${item.numberOfStudents} ${item.totalCollected}`)}>
                                Edit
                            </Button>
                        </Link>
                    }
                </td>
            </tr>
        )) : (
            <tr className='no-class-row'>
                <td colSpan={8}>No Feeding sales</td>
            </tr>
        )}
    </tbody>
    </Table>
  )
}

export default FeedingSalesTable;