import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getClassFees } from '../../functions/fetchEntities';
import { ClassFeeYear } from '../../types/ClassFeeYear';
import Loading from '../Loading';
import ClassFeeTabs from './ClassFeeTabs';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function ClassFeesPage() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [classFees, setClassFees] = useState([] as ClassFeeYear[]);
const [selectedYear, setSelectedYear] = useState({} as ClassFeeYear);

  useEffect(() => {

    const getProperties = async () => {
        const fees = await getClassFees();

        setClassFees(fees);
        setSelectedYear(fees[0]);
    }

    getProperties();

    }, []);


    const handleTermYearChange = (event: React.MouseEvent<HTMLElement>) => {
        const yearPeriod = (event.target as HTMLElement).innerText;

        const selectedYear = classFees.find((classFeeYear) => classFeeYear.periodName === yearPeriod);
        setSelectedYear(selectedYear as ClassFeeYear);
    }
  
    return (
        <>
            {classFees.length > 0 ?
            <div>
                <div className="top-buttons">
                    <div className='select-year-btn'>
                        <Dropdown className="navitem">
                            <Dropdown.Toggle variant="success" id="selectYear">
                                {selectedYear.periodName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {classFees.map((classFeeYear) => (
                                    <Dropdown.Item onClick={handleTermYearChange} key={classFeeYear.periodName}>{classFeeYear.periodName} </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
        
                    <div className='add-new-class-btn'>
                        {
                            canEdit(systemUser) &&
                            <Link className="navitem" to="/ExtraClassFee/new">
                                <Button variant="primary" className="mb-3">
                                    Add Class Fee
                                </Button>
                            </Link>
                        }
                    </div>
                </div>
                {selectedYear.termPeriods && <ClassFeeTabs props={selectedYear} />}
            </div> : <Loading />}
        </>
  )
}

export default ClassFeesPage;