import { Table } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';
import { ReportItem } from '../../types/ReportYear';

function ReportTable(data: any) {

const reports = data.data;

// calculate total total
const total = reports.reduce((total: number, report: ReportItem) => {
    return total + report.total;
}, 0);


const displayNumber = (number: number) => {
    return number !== 0 ? number : '';
}

  return (
    <div>
        <div><h3 className='centered'>{total}</h3></div>
        <Table striped hover responsive>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Book Sales</th>
                    <th>Feeding</th>
                    <th>Extra Class Fee</th>
                    <th>School Fees</th>
                    <th>Exam Fees</th>
                    <th>Uniform Sales</th>
                    <th>Inventory Sales</th>
                    <th>Expenses</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {reports && reports.length > 0 ? (
                    reports.map((report: ReportItem, index: number) => (
                        <tr key={index}>
                            <td>{getDisplayDate(report.date)}</td>
                            <td>{displayNumber(report.bookSaleRevenue)}</td>
                            <td>{displayNumber(report.feedingRevenue)}</td> 
                            <td>{displayNumber(report.extraClassFeeRevenue)}</td> 
                            <td>{displayNumber(report.schoolFeeRevenue)}</td> 
                            <td>{displayNumber(report.examFeeRevenue)}</td> 
                            <td>{displayNumber(report.uniformSalesRevenue)}</td> 
                            <td>{displayNumber(report.inventorySalesRevenue)}</td> 
                            <td>{displayNumber(report.expenses)}</td>  
                            <td>{report.total}</td> 
                        </tr>
                    ))
                ) : (
                    <tr className='no-class-row'>
                        <td colSpan={9}>No reports</td>
                    </tr>
                )}
            </tbody>
        </Table>
    </div>
  )
}

export default ReportTable;