import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { dateIsInThePast, getDisplayDate } from '../../helpers/DateHelper';
import { LibraryBookRentalItem } from '../../types/LibraryBookRentalYear';
import { createButtonTestId, createRowTestId } from '../../helpers/StringHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function LibraryRentalTable(data: any) {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

    const libraryRentalRowHelper = (item: LibraryBookRentalItem) => {

        if (item.actualReturnDate !== null) {
            return "success";
        }
    
        if (dateIsInThePast(item.expectedReturnDate)) {
            return "warning";
        }
            
    }


    const rentals = data.data;

  return (
    <>
    {
        rentals.length > 0 ? 
        <Table striped hover responsive>
            <thead>
                <tr>
                    <th>Student</th>
                    <th>Class</th>
                    <th>Title</th>
                    <th>Date rented</th>
                    <th>Expected return date</th>
                    <th>Actual return date</th>
                    <th>Notes</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data.data.map((item: LibraryBookRentalItem) => (
                    <tr id={createRowTestId(`${item.libraryBook.title}_${item.student.name}_${getDisplayDate(item.rentedDate)}`)} key={item.id} className={`${libraryRentalRowHelper(item)}`}>
                        <td className='item-student'>{item.student.name}</td>
                        <td className='item-class'>{item.student.class.name}</td>
                        <td className='item-title'>{item.libraryBook.title}</td>
                        <td className='item-rented-date'>{getDisplayDate(item.rentedDate)}</td>
                        <td className='item-expected-return-date'>{getDisplayDate(item.expectedReturnDate)}</td>
                        <td className='item-actual-return-date'>{getDisplayDate(item.actualReturnDate)}</td>
                        <td className='item-notes'>{item.notes}</td>
                        <td>
                            {
                                canEdit(systemUser) &&
                                <Link to={`/LibraryBookRental/${item.libraryBookId}/${item.id}`} className="button">
                                    <Button id={createButtonTestId(`${item.libraryBook.title}_${item.student.name}_${getDisplayDate(item.rentedDate)}`)} >
                                        Edit
                                    </Button>
                                </Link>
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
        : 
        <p>No Library Book Rentals</p>}
    </>
    
  )
}

export default LibraryRentalTable;