export const replaceWhiteSpace = (value: string) => {

    return value.replace(/ /g, '_').replace(/\//g, '_');
}


export const createRowTestId = (value: string) => {

    const result = `${replaceWhiteSpace(value)}-row`;

    return result;
}

export const createButtonTestId = (value: string) => {

    const result = `${replaceWhiteSpace(value)}-btn`;

    return result;
}