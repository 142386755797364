import { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { getSchoolFeeTermPayments, getStudent, getTermPeriod } from '../../functions/fetchEntities';
import { getDisplayDate } from '../../helpers/DateHelper';
// import Loading from '../Loading';
import { TermPayment } from '../../types/TermFeePayment';

function SchoolFeePayment() {

const { termId, studentId } = useParams();
const parsedTermId = termId !== undefined ? termId : "";
const parsedStudentId = studentId !== undefined ? studentId : "";


//TODO: make sure IDs is not null

const [termFeePayments, setTermFeePayments] = useState([] as TermPayment[]);
const [totalAmountPaid, setTotalAmountPaid] = useState(0);
const [totalAmountRequired, setTotalAmountRequired] = useState(0);

const [termDisplayName, setTermDisplayName] = useState("");
const [studentDisplayName, setStudentDisplayName] = useState("");


const calculateTotalAmountPaid = (termPayments: TermPayment[]) => {
    let total = 0;
    termPayments.forEach((item: TermPayment) => {
        total += item.amountPaid;
    });

    return total;
}

  useEffect(() => {

    const loadPage = async () => {

    const termFeePayments = await getSchoolFeeTermPayments(parsedTermId, parsedStudentId);
    const termPeriod = await getTermPeriod(parsedTermId);
    const student = await getStudent(parsedStudentId);

    var sDate = new Date(termPeriod.startDate);
    var eDate = new Date(termPeriod.endDate);

    const termName = `${termPeriod.termName} (${sDate.getFullYear()}-${eDate.getFullYear()})`;

    const studentName = `${student.name} (${student.class.name})`;

    const totalPaid = calculateTotalAmountPaid(termFeePayments);
    setTermFeePayments(termFeePayments);
    setTotalAmountPaid(totalPaid);

    setTotalAmountRequired(student.class.termFee);
    setTermDisplayName(termName.toString());
    setStudentDisplayName(studentName);

    }

    loadPage(); 
    }, [parsedStudentId, parsedTermId]);

    

  
    return (
        <>
            {
                <div>
                    <div>
                        <ul>
                            <li>{studentDisplayName} </li>
                            <li>{termDisplayName}</li>
                            <li>{totalAmountPaid}/{totalAmountRequired}</li>
                        </ul>

                        {totalAmountPaid < totalAmountRequired && 
                        <div className='add-new-class-btn'>
                            <Link to={`/SchoolFee/${parsedTermId}/${parsedStudentId}/new/edit`} className="button">
                                <Button>
                                    Add
                                </Button>
                            </Link>    
                        </div>}
                    </div>

                    <div className='page-content'>
                        <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th>Date paid</th>
                                    <th>Amount</th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {termFeePayments.map((payment: TermPayment) => (
                                    <tr key={payment.id}>
                                        <td>{getDisplayDate(payment.paymentDate)}</td>
                                        <td>{payment.amountPaid}</td>
                                        <td>{payment.notes}</td>
                                        <td>
                                            <Link to={`/SchoolFee/${parsedTermId}/${parsedStudentId}/${payment.id}/edit`} className="button">
                                                <Button>
                                                    Edit
                                                </Button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
                
            }

        </>
  )
}

export default SchoolFeePayment;