import { Tab, Tabs } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';
import { getCurrentTerm } from '../../helpers/TermTimeHelper';
import LibraryRentalTable from './LibraryRentalTable';
import { LibraryBookRentalYear } from '../../types/LibraryBookRentalYear';

function LibraryRentalTabs(props:any) {

    var libraryBookRentalYear = props.props as LibraryBookRentalYear;

  return (
    <Tabs defaultActiveKey={getCurrentTerm(libraryBookRentalYear.termPeriods)}>
        {
            libraryBookRentalYear.termPeriods && libraryBookRentalYear.termPeriods.map((termPeriod) => (
                <Tab eventKey={termPeriod.termName} title={termPeriod.termName} key={termPeriod.termName}>
                    <h1 className='centered'>{getDisplayDate(termPeriod.startDate)} - {getDisplayDate(termPeriod.endDate)}</h1>
                    <LibraryRentalTable data={termPeriod.items} />
                </Tab>
            ))
        }
    </Tabs>
  )
}


export default LibraryRentalTabs;