import React from 'react'
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getDisplayDate } from '../../helpers/DateHelper';
import { ClassFeeItem } from '../../types/ClassFeeYear';
import { createButtonTestId, createRowTestId } from '../../helpers/StringHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function ClassFeeTable(data: any) {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

  return (
    <Table striped hover responsive>
    <thead>
        <tr>
            <th>Date</th>
            <th>Number Enrolled</th>
            <th>Number Paid</th>
            <th>Amount Received</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        {data.data.length > 0 ? data.data.map((item: ClassFeeItem) => (
            <tr id={createRowTestId(`${getDisplayDate(item.date)} ${item.numberEnrolled} ${item.numberPaid} ${item.amountReceived}`)} key={item.id}>
                <td className='item-date'>{getDisplayDate(item.date)}</td>
                <td className='item-enrolled'>{item.numberEnrolled}</td>
                <td className='item-paid'>{item.numberPaid}</td>
                <td className='item-received'>{item.amountReceived}</td>
                <td>
                    {
                        canEdit(systemUser) &&
                        <Link to={`/ExtraClassFee/${item.id}`} className="button">
                            <Button id={createButtonTestId(`${getDisplayDate(item.date)} ${item.numberEnrolled} ${item.numberPaid} ${item.amountReceived}`)}>
                                Edit
                            </Button>
                        </Link>
                    }
                </td>
            </tr>
        )) : (
            <tr className='no-class-row'>
                <td colSpan={5}>No Class Fees</td>
            </tr>
        )}
    </tbody>
    </Table>
  )
}

export default ClassFeeTable;