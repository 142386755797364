import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getTermYears } from '../../functions/fetchEntities';
import { getDisplayDate } from '../../helpers/DateHelper';
import { TermYear, TermPeriod } from '../../types/TermYear';
import Loading from '../Loading';
import { createButtonTestId, createRowTestId } from '../../helpers/StringHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function TermYears() {
    
    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

  const [termYears, setTermYears] = useState<TermYear[]>([]);

  useEffect(() => {
    // fetch data
    getTermYears().then((data) => {
        setTermYears(data);
    }
    )

  }, [])

  return (
    <>
        {
            canEdit(systemUser) &&
            <div className='add-new-class-btn'>
                <Link className="navitem" to="/TermYear/new">
                    <Button variant="primary" className="mb-3">
                        Add Term Year
                    </Button>
                </Link>
            </div>
        }

      {termYears ?
        termYears.length > 0 ?
        (
            <div className='page-content'>
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Terms</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {termYears.map((item: TermYear) => (
                            <tr id={createRowTestId(item.periodName)} className='year-row' key={item.id}>
                                <td className='year-name'>{item.periodName}</td>
                                <td className='year-start'>{getDisplayDate(item.startDate)}</td>
                                <td className='year-end'>{getDisplayDate(item.endDate)}</td>
                                <td className='year-terms'>
                                    {
                                        item.termPeriods.map((term: TermPeriod) => (
                                            <div key={term.id}>
                                                <p>{term.termName}: {getDisplayDate(term.startDate)} - {getDisplayDate(term.endDate)}</p>
                                            </div>
                                        ))
                                    }
                                </td>
                                <td>
                                   {
                                    canEdit(systemUser) &&
                                    <Link to={`/TermYear/${item.id}`} className="button">
                                        <Button id={`${createButtonTestId(item.periodName)}`}>
                                            Edit
                                        </Button>
                                    </Link>
                                   }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        ) : <p>No term years found</p>
      : <Loading />}
    </>
  )
}

export default TermYears;