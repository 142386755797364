import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getStaff } from '../../functions/fetchEntities';
import { Teacher } from '../../types/Teacher';
import Loading from '../Loading';
import { createRowTestId, replaceWhiteSpace } from '../../helpers/StringHelper';
import { canEdit } from '../../helpers/UserHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

function Staff() {

  const state = useSelector((state: RootState) => state.systemUser);
  const systemUser = state.systemUser;

  const [teachers, setTeachers] = useState<Teacher[]>([]);

  useEffect(() => {
    // fetch data
    getStaff()
      .then((data) => {
        setTeachers(data);
      }
    )

  }, [])

  return (
    <div>

      {
        canEdit(systemUser) &&
        <div className='add-new-class-btn'>
            <Link className="navitem" to="/Staff/new">
                <Button variant="primary" className="mb-3">
                    Add Staff
                </Button>
            </Link>
        </div>
      }
      

      {teachers.length > 0 ?
      <div className='page-content'>
        {/* <span className="table-title">{teachers.length}</span> */}
        <Table striped hover responsive>
          <thead>
              <tr>
                  <th>Name</th>
                  <th>Class</th>
                  <th>Notes</th>
                  <th></th>
              </tr>
          </thead>
          <tbody>
              {teachers.map((item: Teacher) => (
                  <tr id={createRowTestId(item.name)} key={item.id} className='staff-table-row'>
                      <td className='staff-name'>{item.name}</td>
                      <td className='staff-class'>{item.class.name}</td>
                      <td className='staff-notes'>{item.notes}</td>
                      <td>
                        {
                          canEdit(systemUser) &&
                          <Link to={`/Staff/${item.id}`} className="button">
                              <Button id={`${replaceWhiteSpace(item.name)}-btn`}>
                                  Edit
                              </Button>
                          </Link>
                        }
                          
                      </td>
                  </tr>
              ))}
          </tbody>
      </Table> 
      </div>
        : <Loading />}
    </div>
  )
}

export default Staff;