import { Tab, Tabs } from 'react-bootstrap';
import { getDisplayDate } from '../../../helpers/DateHelper';
import { getCurrentTerm } from '../../../helpers/TermTimeHelper';
import { PandCUniformSalesYear } from '../../../types/PandCUniformSalesYear';
import PandCUnformSalesTable from './PandCUnformSalesTable';

function UniformSalesTabs(props:any) {

    var uniformSalesYear = props.selectedYear as PandCUniformSalesYear;

  return (
    <Tabs defaultActiveKey={getCurrentTerm(uniformSalesYear.termPeriods)}>
        {
            uniformSalesYear.termPeriods && uniformSalesYear.termPeriods.map((termPeriod) => (
                <Tab eventKey={termPeriod.termName} title={termPeriod.termName} key={termPeriod.termName}>
                    <h1 className='centered'>{getDisplayDate(termPeriod.startDate)} - {getDisplayDate(termPeriod.endDate)}</h1>
                    <PandCUnformSalesTable data={termPeriod.items} />
                </Tab>
            ))
        }
    </Tabs>
  )
}

export default UniformSalesTabs;