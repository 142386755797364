import React, { useEffect, useState } from 'react'
import { Accordion, Button, Form, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { getClasses } from '../../functions/fetchEntities';
import { getDisplayDate } from '../../helpers/DateHelper';
import { Class } from '../../types/Class';
import { Student } from '../../types/Student';
import Loading from '../Loading';
import { createRowTestId } from '../../helpers/StringHelper';
import { countTotalStudentsInClass } from '../../helpers/ClassHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function Students() {

  const state = useSelector((state: RootState) => state.systemUser);
  const systemUser = state.systemUser;

  const navigate = useNavigate();


  const [classes, setClasses] = useState<Class[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<Student[]>([]);

  const handleCheckStudent = (id: string) => {

    const stu = selectedStudents.find(student => student.id === id);

    if (stu) {
      setSelectedStudents(selectedStudents.filter(student => student.id !== id));
    }else{
      setSelectedStudents([...selectedStudents, classes.find(c => c.students.find(s => s.id === id))?.students.find(s => s.id === id) as Student]);
    }

  }


  const editMultipleStudents = async (event: any) => {
    event.preventDefault();

    const groupedStudents = selectedStudents.filter(s => s.classId === event.target.id);

    navigate('/EditMultipleStudents', { state: groupedStudents });
  }

  useEffect(() => {
    // fetch data
    getClasses().then((data) => 
    {
      setClasses(data);
    })

  }, [])

  return (
    <>
    {
      canEdit(systemUser) &&
      <div className='add-new-class-btn'>
            <Link className="navitem" to="/Student/new">
                <Button variant="primary" className="mb-3">
                    Add Student
                </Button>
            </Link>
        </div>
    }
      
        {classes.length > 0 ?

        <div>
          <Accordion defaultActiveKey={null} >
          {classes.map((item: Class) => (
              <Accordion.Item key={item.id} eventKey={item.name} className='student-class-accordion'>
                  <Accordion.Header>{item.name} - ({countTotalStudentsInClass(item.students)})</Accordion.Header>
                  <Accordion.Body>
                    {item.students.length > 0 ? 
                    <div>
                      {selectedStudents.filter(s => s.classId === item.id).length > 0 &&
                      <div>
                        <Button id={item.id} variant="primary" className="mb-3" onClick={editMultipleStudents}>
                          Edit
                        </Button>
                        
                      </div>
                        }
                      <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>DOB</th>
                                <th>Scholarship Type</th>
                                {item.name === "PREVIOUS STUDENTS" && <th>Date Left</th>}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {item.students.map((student: Student) => {

                              return (
                                <tr id={createRowTestId(student.name)} key={student.id} className={`${student.dateLeft ? "warning":""}`}>
                                    <td>
                                      {
                                        canEdit(systemUser) &&
                                        <Form.Check aria-label={student.name} onClick={e => handleCheckStudent(student.id)} />
                                      }
                                    </td>
                                    <td className='student-name'>{student.name}</td>
                                    <td className='student-dob'>{getDisplayDate(student.dob)}</td>
                                    <td className='student-scholarship-type'>{student.scholarshipType}</td>
                                    {item.name === "PREVIOUS STUDENTS" && <td className='student-date-left'>{getDisplayDate(student.dateLeft)}</td>}
                                    
                                    <td>
                                      {
                                        canEdit(systemUser) &&
                                        <Link to={`/Student/${student.id}`} className="button">
                                            <Button id={`${student.name}-btn`}>
                                                Edit
                                            </Button>
                                        </Link>
                                      }
                                        
                                    </td>
                                </tr>
                            )
                            })}
                        </tbody>
                      </Table>
                    </div> : <div>No students in this class</div>}
                  </Accordion.Body>
              </Accordion.Item>
              ))}
        </Accordion>
        </div> : <Loading /> }

    </>
  )
}

export default Students;