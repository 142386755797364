import { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RequestAccessToken, fetchUsers } from '../../functions/fetchGraph';
import { SystemUser } from '../../types/SystemUser';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

function Users() {

    const state = useSelector((state: RootState) => state.msalInstance);
    const msalInstance = state.msalInstance;
    const [users, setUsers] = useState([] as SystemUser[]);

  useEffect(() => { 

    const loadPage = async () => {
      const accessToken = await RequestAccessToken(msalInstance);
      const users = await fetchUsers(accessToken);
      setUsers(users);
    }

    loadPage();

  });

  return (
   <div>
    <div className='add-new-class-btn'>
        <Link to={`/SystemUser/new`} className="button">
            <Button>
                Invite
            </Button>
        </Link>
    </div>

    <div className='page-content'>
        <Table striped hover responsive>
        <thead>
            <tr>
                <th>Name</th>
                <th>login</th>
                <th>Job role</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {users?.map((user: SystemUser) => (
                <tr key={user.id}>
                    <td>{user.displayName}</td>
                    <td>{user.mail}</td>
                    <td>{user.jobTitle}</td>
                    <td>
                        <Link to={`/SystemUser/${user.id}`} className="button">
                            <Button>
                                Edit
                            </Button>
                        </Link>
                    </td>
                </tr>
            ))}
        </tbody>
        </Table>
    </div>
   </div>
  )
}

export default Users;