import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { deleteStaffMember, getClasses, getStaffMember, postStaff } from '../../functions/fetchEntities';
import { Class } from '../../types/Class';
import { Teacher } from '../../types/Teacher';
import Loading from '../Loading';

function EditStaff() {

const [validated, setValidated] = useState(false);
const [selectedTeacher, setSelectedTeacher] = useState({} as Teacher);
const [classes, setClasses] = useState([] as Class[]);
const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        getStaffMember(parsedId)
            .then((data) => setSelectedTeacher(data));
    }

    getClasses()
        .then((data) => setClasses(data));


        
    }, [parsedId]);

 
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setSelectedTeacher({...selectedTeacher, name: name});
    }

    const handleClassChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const classId = event.target.value;
        setSelectedTeacher({...selectedTeacher, classId: classId});
    }

    const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const notes = event.target.value;
        setSelectedTeacher({...selectedTeacher, notes: notes});
    }

    const handleDelete = async () => {
        if (window.confirm(`Are you sure you want to delete ${selectedTeacher.name}`)) {

            await deleteStaffMember(selectedTeacher.id);
      
            alert("Teacher deleted");
            navigate('/Staff', {replace: true});
          }
    }

    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            await postStaff(selectedTeacher);
            navigate('/Staff', {replace: true});
        }
    
        setValidated(true);
      };

  
    return (
        <>
             {parsedId === "new" || selectedTeacher.id ? 
             <div>
             <h1>Edit Teacher</h1>
    
                <div className='form-background'>
                <div className='form-container'>
                    <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>


                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control 
                        id="edit-name"
                        type="text" 
                        placeholder="Staff Name" 
                        onChange={handleNameChange} 
                        value={selectedTeacher.name} 
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formClass">
                        <Form.Label>Class</Form.Label>
                        <select id="edit-class" className="form-select" aria-label="Class" onChange={handleClassChange} required>
                            <option value="">Select a class</option>
                            {classes.map((classItem) => (
                                <option key={classItem.id} value={classItem.id} selected={selectedTeacher.classId === classItem.id}>{classItem.name}</option>
                            ))}
                        </select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formNotes">
                        <Form.Label>Notes</Form.Label>
                        <textarea className="form-control" id="edit-notes" rows={3} onChange={handleNotesChange} value={selectedTeacher.notes} />
                    </Form.Group>


                    <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                        Save
                    </Button>
                    <Button className='edit-form-submit' variant="danger" onClick={handleDelete} >
                        Delete
                    </Button>
                    </Form>
                </div>
                </div>
             </div> : <Loading />}
        </>
  )
}

export default EditStaff;