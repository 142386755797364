import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';
import { getCurrentTerm } from '../../helpers/TermTimeHelper';
import { SchoolFeesYear } from '../../types/SchoolFeesYear';
// import { UniformSalesYear } from '../../types/UniformSalesYear';
import SchoolFeesTable from './SchoolFeesTable';

function SchoolFeesTabs(props:any) {

    var schoolFeesYear = props.props as SchoolFeesYear;

  return (
    <Tabs defaultActiveKey={getCurrentTerm(schoolFeesYear.termPeriods)}>
        {
            schoolFeesYear.termPeriods && schoolFeesYear.termPeriods.map((termPeriod) => (
                <Tab eventKey={termPeriod.termName} title={termPeriod.termName} key={termPeriod.termName}>
                    <h1 className='centered'>{getDisplayDate(termPeriod.startDate)} - {getDisplayDate(termPeriod.endDate)}</h1>
                    
                    <SchoolFeesTable data={termPeriod} />
                </Tab>
            ))
        }
    </Tabs>
  )
}


export default SchoolFeesTabs;