import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getAccountTransactions } from '../../functions/fetchEntities';
import Loading from '../Loading';
import AccountTransactionsTabs from './AccountTransactionsTabs';
import { AccountTransactionYear } from '../../types/AccountTransactionYear';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function AccountTransactionsPage() {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

const [accountTransactions, setAccountTransactions] = useState([] as AccountTransactionYear[]);
const [selectedYear, setSelectedYear] = useState({} as AccountTransactionYear);

  useEffect(() => {

    const getProperties = async () => {
        const accountTransactions = await getAccountTransactions();

        setAccountTransactions(accountTransactions);
        setSelectedYear(accountTransactions[0]);
    }

    getProperties();

    }, []);


    const handleTermYearChange = (event: React.MouseEvent<HTMLElement>) => {
        const yearPeriod = (event.target as HTMLElement).innerText;

        const selectedYear = accountTransactions.find((accountTransaction) => accountTransaction.periodName === yearPeriod);
        setSelectedYear(selectedYear as AccountTransactionYear);
    }
  
    return (
        <>
            {accountTransactions.length > 0 ?
            <div>
            <div className="top-buttons">
                <div className='select-year-btn'>
                    <Dropdown className="navitem">
                        <Dropdown.Toggle variant="success" id="selectYear">
                            {selectedYear.periodName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {accountTransactions.map((accountTransaction) => (
                                <Dropdown.Item onClick={handleTermYearChange} key={accountTransaction.periodName}>{accountTransaction.periodName} </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                {
                    canEdit(systemUser) &&
                    <div className='add-new-class-btn'>
                        <Link className="navitem" to="/AccountTransaction/new">
                            <Button variant="primary" className="mb-3">
                                Add Account Transaction
                            </Button>
                        </Link>
                    </div>
                }
    
                
            </div>
            {selectedYear.termPeriods && <AccountTransactionsTabs props={selectedYear} />}
        </div> : <Loading />}
        </>
  )
}

export default AccountTransactionsPage;