import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { getLibraryBookRentals } from '../../functions/fetchEntities';
import Loading from '../Loading';
import LibraryRentalTabs from './LibraryRentalTabs';
import { LibraryBookRentalYear } from '../../types/LibraryBookRentalYear';

function FeedingSalesPage() {

const [libraryRentalYears, setLibraryRentals] = useState([] as LibraryBookRentalYear[]);
const [selectedYear, setSelectedYear] = useState({} as LibraryBookRentalYear);

  useEffect(() => {

    const getProperties = async () => {
        const libraryRentals = await getLibraryBookRentals();

        setLibraryRentals(libraryRentals);
        setSelectedYear(libraryRentals[0]);
    }

    getProperties();

    }, []);


    const handleTermYearChange = (event: React.MouseEvent<HTMLElement>) => {
        const yearPeriod = (event.target as HTMLElement).innerText;

        const selectedYear = libraryRentalYears.find((libraryRentalYear) => libraryRentalYear.periodName === yearPeriod);
        setSelectedYear(selectedYear as LibraryBookRentalYear);
    }
  
    return (
        <>
            {libraryRentalYears ?
            <div>
            <div className="top-buttons">
                <div className='select-year-btn'>
                    <Dropdown className="navitem">
                        <Dropdown.Toggle variant="success" id="selectYear">
                            {selectedYear.periodName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {libraryRentalYears.map((libraryRentalYear) => (
                                <Dropdown.Item onClick={handleTermYearChange} key={libraryRentalYear.periodName}>{libraryRentalYear.periodName} </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

            </div>
            {selectedYear.termPeriods && <LibraryRentalTabs props={selectedYear} />}
        </div> : <Loading />}
        </>
  )
}

export default FeedingSalesPage;