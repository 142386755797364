import { ExtraClassFeeItemRequest } from "../types/RequestModels/ExtraClassFeeItemRequest";


export const ExtraClassFeeItemMapper = {

    mapExtraClassFeeItemRequestToExtraClassFeeItem: (extraClassFeeItemRequest: ExtraClassFeeItemRequest) => {
        return {

            id: extraClassFeeItemRequest.id,
            date: extraClassFeeItemRequest.date,
            numberEnrolled: extraClassFeeItemRequest.numberEnrolled,
            numberPaid: extraClassFeeItemRequest.numberPaid,
            amountReceived: extraClassFeeItemRequest.amountReceived,
            updatedBy: extraClassFeeItemRequest.updatedBy,
        };
    },

    mapClassFeeItemToClassRequest: (extraClassFeeItemToMap: any) => {
            
            return {
                id: extraClassFeeItemToMap.id,
                date: extraClassFeeItemToMap.date,
                numberEnrolled: extraClassFeeItemToMap.numberEnrolled,
                numberPaid: extraClassFeeItemToMap.numberPaid,
                amountReceived: extraClassFeeItemToMap.amountReceived,
                updatedBy: extraClassFeeItemToMap.updatedBy,
            };
        }
};


