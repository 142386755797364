import { ExpenseTypeRequest } from "../types/RequestModels/ExpenseTypeRequest";
import { ExpenseType } from "../types/ExpenseType";

export const ExpenseTypeMapper = {

    mapExpenseTypeRequestToExpenseType: (expenseTypeRequest: ExpenseTypeRequest): ExpenseType => {
        return {
            id: expenseTypeRequest.id,
            name: expenseTypeRequest.name,
            isCritical: expenseTypeRequest.isCritical,
        } as ExpenseType;

    },

    mapExpenseTypeToExpenseTypeRequest: (expenseType: any): ExpenseTypeRequest => {
        return {
            id: expenseType.id,
            name: expenseType.name,
            isCritical: expenseType.isCritical,
        } as ExpenseTypeRequest;
    }

};