import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { getClasses, updateMultipleStudents } from '../../functions/fetchEntities';
import { disableTyping, getCalendarDate, maxDate, minDate } from '../../helpers/DateHelper';
import { Class } from '../../types/Class';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { StudentRequest } from '../../types/RequestModels/StudentRequest';


function EditMultipleStudent() {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

    const location = useLocation();
  
    const [hasBeenEdited, setHasBeenEdited] = useState(false);
    const [classes, setClasses] = useState([] as Class[]);
    const [selectedStudents, setSelectedStudents] = useState(location.state as StudentRequest[]);
    const [originalClassName, setOriginalClassName] = useState("");
    const [selectedDateLeft, setSelectedDateLeft] = useState(undefined as Date | undefined);

    useEffect(() => {

        const loadPage = async () => {
            const classes = await getClasses();
            const ogClassName = classes.find(c => c.id === selectedStudents[0].classId)?.name;
            setOriginalClassName(ogClassName as string);
            setClasses(classes);
        }

        loadPage();
  
    }, [selectedStudents]);
  
    const navigate = useNavigate();
  
    const handleClassChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const classId = event.target.value;
        const updatedStudents = selectedStudents.map(s => {
            s.classId = classId;
            s.updatedBy = systemUser.displayName;
            return s;
        });

        setSelectedStudents(updatedStudents);
        setHasBeenEdited(true);
    }

    const handleDateLeftChange = (event: ChangeEvent<HTMLInputElement>) => {
        const dateLeft = new Date(event.target.value);

        const updatedStudents = selectedStudents.map(s => {
            s.dateLeft = dateLeft;
            s.updatedBy = systemUser.displayName;
            return s;
        });

        setSelectedStudents(updatedStudents);
        setSelectedDateLeft(dateLeft);
        setHasBeenEdited(true);
    }
  
    const handleSubmit = async (event: any) => {
      event.preventDefault();
  
     if (hasBeenEdited) {
        await updateMultipleStudents(selectedStudents);
        navigate('/Students', {replace: true});
     }
    }
  
    return (
      <>
        <h1 className='centered'>Edit multiple students - {originalClassName}</h1>
        <ul>
            {selectedStudents.map((student) => (
                <li key={student.id}>
                    {student.name}
                </li>
            ))}
        </ul>
        <div className='form-background'>
          <div className='form-container'>
            <Form>

                <Form.Group className="mb-3" controlId="formClass">
                    <Form.Label>Class</Form.Label>
                    <select className="form-select" aria-label="Class" onChange={handleClassChange} required>
                        <option value="">Select a class</option>
                        {classes.map((classItem) => (
                            <option key={classItem.id} value={classItem.id} selected={originalClassName === classItem.name}>{classItem.name}</option>
                        ))}
                    </select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formDateLeft">
                    <Form.Label>Date Left</Form.Label>
                    <Form.Control 
                        type="date" 
                        placeholder="Date Left" 
                        value={getCalendarDate(selectedDateLeft)} 
                        onChange={handleDateLeftChange}
                        onKeyDown={(e) => disableTyping(e)}
                        min={minDate}
                        max={maxDate}
                         />
                </Form.Group>     
  
              <Button className='edit-form-submit' variant="primary" type="submit" onClick={event => handleSubmit(event)}>
                Save
              </Button>
            </Form>
          </div>
        </div>
      </>
  
    )

}

export default EditMultipleStudent;