import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { getExamFees } from '../../functions/fetchEntities';
import Loading from '../Loading';
import ExamFeesTabs from './ExamFeesTabs';
import { ExamFeesYear } from '../../types/ExamFeesYear';

function ExamFeesPage() {

const [examFees, setExamFeesYears] = useState([] as ExamFeesYear[]);
const [selectedYear, setSelectedYear] = useState({} as ExamFeesYear);

  useEffect(() => {

    const getProperties = async () => {
        const examFees = await getExamFees();

        setExamFeesYears(examFees);
        setSelectedYear(examFees[0]);
    }

    getProperties();

    }, []);


    const handleTermYearChange = (event: React.MouseEvent<HTMLElement>) => {
        const yearPeriod = (event.target as HTMLElement).innerText;

        const selectedYear = examFees.find((examFee) => examFee.periodName === yearPeriod);
        setSelectedYear(selectedYear as ExamFeesYear);
    }
  
    return (
        <>
            {examFees ?
            <div>
                <div className="top-buttons">
                    <div className='select-year-btn'>
                        <Dropdown className="navitem">
                            <Dropdown.Toggle variant="success" id="selectYear">
                                {selectedYear.periodName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {examFees.map((examFee) => (
                                    <Dropdown.Item onClick={handleTermYearChange} key={examFee.periodName}>{examFee.periodName} </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                {selectedYear.termPeriods && <ExamFeesTabs props={selectedYear} />}
            </div> : <Loading />}
        </>
  )
}

export default ExamFeesPage;