import React from 'react'
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getDisplayDate } from '../../helpers/DateHelper';
import { BookSaleItem } from '../../types/BookSalesYear';

function BookSalesTable(data: any) {
  return (
    <Table striped hover responsive>
    <thead>
        <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Price per book</th>
            <th>No of books sold</th>
            {/* <th>Revenue</th> */}
            <th></th>
        </tr>
    </thead>
    <tbody>
        {data.data.length > 0 ? data.data.map((item: BookSaleItem) => (
            <tr key={item.id}>
                <td>{getDisplayDate(item.date)}</td>
                <td>{item.inventoryItem.name}</td>
                <td>{item.inventoryItem.sellingPrice}</td>
                <td>{item.noOfBooksSold}</td>
                {/* <td>{item.revenue}</td> */}
                <td>
                    <Link to={`/BookSale/${item.id}`} className="button">
                        <Button>
                            Edit
                        </Button>
                    </Link>
                </td>
            </tr>
        )) : (
            <tr className='no-class-row'>
                <td colSpan={5}>No book sales</td>
            </tr>
        )}
    </tbody>
    </Table>
  )
}

export default BookSalesTable;