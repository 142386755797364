import { InventoryItemRequest } from "../types/RequestModels/InventoryItemRequest";
import { InventoryItem } from "../types/InventoryItem";

export const InventoryItemMapper = {

    mapInventoryItemRequestToInventoryItem: (inventoryItemRequest: InventoryItemRequest): InventoryItem => {
        return {
            id: inventoryItemRequest.id,
            name: inventoryItemRequest.name,
            inventoryTypeId: inventoryItemRequest.inventoryTypeId,
            quantity: inventoryItemRequest.quantity,
            costPerUnit: inventoryItemRequest.costPerUnit,
            sellingPrice: inventoryItemRequest.sellingPrice,
            isSellable: inventoryItemRequest.isSellable,
            inventoryType: inventoryItemRequest.inventoryType,
        } as InventoryItem;

    },

    mapInventoryItemToInventoryItemRequest: (inventoryItem: any): InventoryItemRequest => {
        return {
            id: inventoryItem.id,
            name: inventoryItem.name,
            inventoryTypeId: inventoryItem.inventoryTypeId,
            quantity: inventoryItem.quantity,
            costPerUnit: inventoryItem.costPerUnit,
            sellingPrice: inventoryItem.sellingPrice,
            isSellable: inventoryItem.isSellable,
            inventoryType: inventoryItem.inventoryType,
        } as InventoryItemRequest;
    }

};