import { useMsal } from "@azure/msal-react";
import { Nav } from 'react-bootstrap';



const SignOutButton = ({ user }: any) => {
    const { instance } = useMsal();

    const handleSignOut = () => {
      instance.logoutRedirect();
    }

  return (
    <Nav.Link onClick={handleSignOut} >
      Sign Out {user && `(${user})`}
    </Nav.Link>
  )
}

export default SignOutButton;