import { PublicClientApplication } from '@azure/msal-browser';
import { createSlice } from '@reduxjs/toolkit';
import { SystemUser } from './types/SystemUser';

const initialState = {
  msalInstance: {} as PublicClientApplication,
  systemUser: {} as SystemUser
};

const msalInstanceSlice = createSlice({
  name: 'msalInstance',
  initialState,
  reducers: {
    setMsalInstance: (state, action: any) => {
      state.msalInstance = action.payload;
    },
    setSystemUser: (state, action: any) => {
      state.systemUser = action.payload;
    },
  },
});

export const { setMsalInstance, setSystemUser } = msalInstanceSlice.actions;

export default msalInstanceSlice.reducer;
