import { InventoryTypeRequest } from "../types/RequestModels/InventoryTypeRequest";
import { InventoryType } from "../types/InventoryType";

export const InventoryTypeMapper = {

    mapInventoryTypeRequestToInventoryType: (inventoryTypeRequest: InventoryTypeRequest): InventoryType => {
        return {
            id: inventoryTypeRequest.id,
            name: inventoryTypeRequest.name,
            isCritical: inventoryTypeRequest.isCritical,
        } as InventoryType;

    },

    mapInventoryTypeToInventoryTypeRequest: (inventoryType: any): InventoryTypeRequest => {
        return {
            id: inventoryType.id,
            name: inventoryType.name,
            isCritical: inventoryType.isCritical,
        } as InventoryTypeRequest;
    }

};