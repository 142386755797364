import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getInventorySalesSales, getInventoryTypes } from '../../functions/fetchEntities';
import Loading from '../Loading';
import InventorySalesTabs from './InventorySalesTabs';
import { InventorySalesYear } from '../../types/InventorySalesYear';
import { InventoryType } from '../../types/InventoryType';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function InventorySalesPage() {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

const [inventorySales, setInventorySales] = useState([] as InventorySalesYear[]);
const [selectedYear, setSelectedYear] = useState({} as InventorySalesYear);

const [inventoryTypes, setInventoryTypes] = useState<InventoryType[]>([]);

  useEffect(() => {

    const getProperties = async () => {
        const inventorySales = await getInventorySalesSales();

        getInventoryTypes()
            .then((data) => {

                var newTypes = data.filter(ut => ut.name !== "Uniform" && ut.name !== "Material");

                setInventoryTypes(newTypes)
            });

        setInventorySales(inventorySales);
        setSelectedYear(inventorySales[0]);
    }

    getProperties();

    }, []);


    const handleTermYearChange = (event: React.MouseEvent<HTMLElement>) => {
        const yearPeriod = (event.target as HTMLElement).innerText;

        const selectedYear = inventorySales.find((inventoryFeeYear) => inventoryFeeYear.periodName === yearPeriod);
        setSelectedYear(selectedYear as InventorySalesYear);
    }
  
    return (
        <>
            {inventorySales.length > 0 ?
            <div>
                <div className="top-buttons">
                    <div className='select-year-btn'>
                        <Dropdown className="navitem">
                            <Dropdown.Toggle variant="success" id="selectYear">
                                {selectedYear.periodName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {inventorySales.map((inventorySaleYear) => (
                                    <Dropdown.Item onClick={handleTermYearChange} key={inventorySaleYear.periodName}>{inventorySaleYear.periodName} </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
        
                    {
                        canEdit(systemUser) &&
                        <div className='add-new-class-btn'>
                            <Link className="navitem" to="/InventorySale/new">
                                <Button variant="primary" className="mb-3">
                                    Add inventory sale
                                </Button>
                            </Link>
                        </div>
                    }
                </div>
                {selectedYear.termPeriods && <InventorySalesTabs selectedYear={selectedYear} inventoryTypes={inventoryTypes} />}
            </div> : <Loading />}
        </>
  )
}

export default InventorySalesPage;