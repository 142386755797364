import { InteractionType } from '@azure/msal-browser';
import { useMsalAuthentication } from '@azure/msal-react';
import { useEffect, useState } from 'react'
// import ProfileData from './ProfileData';
import { loginRequest } from '../authConfig';
import { fetchLoggedInUser } from '../functions/fetchGraph';

import img from './marinel_image.png'

function Home() {
  const [graphData, setGraphData] = useState(null);
  const {result, error} = useMsalAuthentication(InteractionType.Popup, loginRequest);

  useEffect(() => { 
    if (!!graphData) {
      return;
    }

    if(!!error){
      console.log(error);
      return;
    }

    const getLoggedInUser = async (accessToken: string) => {
      const loggedInUser = await fetchLoggedInUser(accessToken)
      setGraphData(loggedInUser);
    }

    if (result) {
     const { accessToken } = result;

     getLoggedInUser(accessToken);
    }

  }, [graphData, error, result]);

  return (
    <>
      <h1 className='page-title'>Marinel's Mission</h1>
      <div className='home-page-p-container'>
        <p className='home-page-p'>
          The Marinel Foundation is a charitable organisation that has been established to advance the education and livelihood of socio-economically disadvantaged children. Our mission is to support children and schools by providing much-needed funding for resources such as books, computers, classrooms, food and sponsored/free educational scholarships. Marinel Foundation operates throughout the UK and Ghana.
        </p>

      </div>

      <div className='home-page-p-container'>
        <img className='home-page-img' src={img} alt="home-page-img" />
      </div>
      {/* {graphData ? <ProfileData graphData={graphData} /> : <div>Loading...</div>} */}
    </>
  )
}

export default Home;