import { ExpenseItemRequest } from "../types/RequestModels/ExpenseItemRequest";
import { ExpenseItem } from "../types/ExpenseItem";

export const ExpenseItemMapper = {

    mapExpenseItemRequestToExpenseItem: (expenseItemRequest: ExpenseItemRequest): ExpenseItem => {
        return {
            id: expenseItemRequest.id,
            expenseTypeId: expenseItemRequest.expenseTypeId,
            type: expenseItemRequest.type,
            date: expenseItemRequest.date,
            description: expenseItemRequest.description,
            quantity: expenseItemRequest.quantity,
            amount: expenseItemRequest.amount,
            total: expenseItemRequest.total,
        } as ExpenseItem;

    },

    mapExpenseItemToExpenseItemRequest: (expenseItem: any): ExpenseItemRequest => {
        return {
            id: expenseItem.id,
            expenseTypeId: expenseItem.expenseTypeId,
            type: expenseItem.type,
            date: expenseItem.date,
            description: expenseItem.description,
            quantity: expenseItem.quantity,
            amount: expenseItem.amount,
            total: expenseItem.total,
        } as ExpenseItemRequest;
    }

};