import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { deleteLibraryRental, getClasses, getLibraryBookRental, getStudenstByClassId, postLibraryBookRental } from '../../functions/fetchEntities';
import { Class } from '../../types/Class';
import { Student } from '../../types/Student';
import Loading from '../Loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { LibraryBookRentalRequest } from '../../types/RequestModels/LibraryBookRentalRequest';
import { LibraryMapper } from '../../mappers/LibraryMapper';
import { disableTyping, getCalendarDate, getDisplayDate, maxDate, minDate } from '../../helpers/DateHelper';

function EditLibraryBookRental() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [classes, setClasses] = useState([] as Class[]);
const [students, setStudents] = useState([] as Student[]);

const navigate = useNavigate();

const { id, bookId } = useParams();
const parsedBookId = bookId !== undefined ? bookId : "";
const parsedId = id !== undefined ? id : "";

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [selectedLibraryBookRental, setLibraryBookRental] = useState({libraryBookId: parsedBookId, rentedDate: new Date()} as LibraryBookRentalRequest);


  useEffect(() => {

    if (parsedId !== "new") {
        getLibraryBookRental(parsedId)
            .then((data) => setLibraryBookRental(LibraryMapper.mapLibraryBookRentalToLibraryBookRentalRequest(data)));
    }

    getClasses()
        .then((data) => setClasses(data));

    }, [parsedId]);

 
    const handleClassChange = (event: any) => {
        const classId = event.target.value;

        getStudenstByClassId(classId)
            .then((data) => setStudents(data));

        setHasBeenEdited(true);

    }

    const handleStudentChange = (event: any) => {
        const studentId = event.target.value;

        setLibraryBookRental({...selectedLibraryBookRental, studentId: studentId});
        setHasBeenEdited(true);
    }

    const handleRentedDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = new Date(event.target.value);
        setLibraryBookRental({...selectedLibraryBookRental, rentedDate: date});


        setHasBeenEdited(true);
    }

    const handleExpectedReturnDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = new Date(event.target.value);
        setLibraryBookRental({...selectedLibraryBookRental, expectedReturnDate: date});

        setHasBeenEdited(true);
    }

    const handleActualReturnDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = new Date(event.target.value);
        setLibraryBookRental({...selectedLibraryBookRental, actualReturnDate: date});

        setHasBeenEdited(true);
    }

    const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const notes = event.target.value;
        setLibraryBookRental({...selectedLibraryBookRental, notes: notes});
        setHasBeenEdited(true);
    }

    const handleDelete = async () => {
        if (window.confirm(`Are you sure you want to delete ${selectedLibraryBookRental.libraryBook.title} for ${selectedLibraryBookRental.student.name}`)) {

            await deleteLibraryRental(selectedLibraryBookRental.id);
      
            alert("Rental deleted");
            navigate('/Library#Rentals', {replace: true});
          }
    }


    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{

            if (hasBeenEdited) {
                selectedLibraryBookRental.updatedBy = systemUser.displayName;
                await postLibraryBookRental(selectedLibraryBookRental);
                navigate('/Library#Rentals', {replace: true});
            }
        }
    
        setValidated(true);
      };

  
    return (
        <>
            {parsedId === "new" || selectedLibraryBookRental.id ?
            <div>
                <h1>Edit libary rental</h1>
        
                <div className='form-background'>
                <div className='form-container'>
                    <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                    {!selectedLibraryBookRental.id ?
                    <div>

                    <Form.Group className="mb-3" controlId="formClasses">
                        <Form.Label>Class</Form.Label>
                        <select id="edit-class" className="form-select" aria-label="Classes" onChange={handleClassChange} required>
                            <option value="">Select a class</option>
                            {classes.map((classItem) => (
                                <option key={classItem.id} 
                                value={classItem.id} 
                                >
                                    {classItem.name}
                                </option>
                            ))}
                        </select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formStudent">
                        <Form.Label>Student</Form.Label>
                        <select id="edit-student" className="form-select" aria-label="Students" onChange={handleStudentChange} required>
                            <option value="">Select a student</option>
                            {students.map((student) => (
                                <option key={student.id} 
                                value={student.id} 
                                >
                                    {student.name}
                                </option>
                            ))}
                        </select>
                    </Form.Group>

                        <Form.Group className="mb-3" controlId="formDate">
                            <Form.Label>Rented Date</Form.Label>
                            <Form.Control 
                            type="date" 
                            placeholder="Date" 
                            value={getCalendarDate(selectedLibraryBookRental.rentedDate)} 
                            onChange={handleRentedDateChange} 
                            onKeyDown={(e) => disableTyping(e)}
                            min={minDate}
                            max={maxDate}
                            required
                            />
                        </Form.Group>                           
                 
                </div> :
                <div>
                    <ul className='no-list-style'>
                        <li>Student: {selectedLibraryBookRental.student.name}</li>
                        <li>Class: {selectedLibraryBookRental.student.class.name}</li>
                        <li>Date rented: {getDisplayDate(selectedLibraryBookRental.rentedDate)}</li>

                    </ul>

                    <Form.Group className="mb-3" controlId="formDate">
                            <Form.Label>Expected return date</Form.Label>
                            <Form.Control 
                            type="date" 
                            placeholder="Date" 
                            value={getCalendarDate(selectedLibraryBookRental.expectedReturnDate)} 
                            onChange={handleExpectedReturnDateChange} 
                            onKeyDown={(e) => disableTyping(e)}
                            min={minDate}
                            max={maxDate}
                            required
                            />
                        </Form.Group>  

                        <Form.Group className="mb-3" controlId="formDate">
                            <Form.Label>Actual return date</Form.Label>
                            <Form.Control 
                            type="date" 
                            placeholder="Date" 
                            value={getCalendarDate(selectedLibraryBookRental.actualReturnDate)} 
                            onChange={handleActualReturnDateChange} 
                            onKeyDown={(e) => disableTyping(e)}
                            min={getCalendarDate(selectedLibraryBookRental.rentedDate)}
                            max={maxDate}
                            />
                        </Form.Group> 

                    <Form.Group className="mb-3" controlId="formNotes">
                        <Form.Label>Notes</Form.Label>
                        <textarea id="edit-notes" className="form-control" rows={3} onChange={handleNotesChange} value={selectedLibraryBookRental.notes} />
                    </Form.Group>
                </div>
                
                }

                    <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                        Save
                    </Button>
                    <Button id="delete" className='edit-form-submit' variant="danger" onClick={handleDelete} >
                        Delete
                    </Button>
                    </Form>
                </div>
                </div>
            </div> : <Loading />}
        </>
  )
}

export default EditLibraryBookRental;