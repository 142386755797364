import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getExpense, getExpenseTypes, postExpense } from '../../functions/fetchEntities';
import { ExpenseType } from '../../types/ExpenseType';
import Loading from '../Loading';
import { ExpenseItemRequest } from '../../types/RequestModels/ExpenseItemRequest';
import { ExpenseItemMapper } from '../../mappers/ExpenseItemMapper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { disableTyping, getCalendarDate, maxDate, minDate } from '../../helpers/DateHelper';

function EditExpense() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [selectedExpense, setExpense] = useState({date: new Date()} as ExpenseItemRequest);
const [expenseTypes, setExpenseTypes] = useState<ExpenseType[]>([]);

const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        getExpense(parsedId)
            .then((data) => setExpense(ExpenseItemMapper.mapExpenseItemToExpenseItemRequest(data)));
    }

    getExpenseTypes().then((data) => {
        setExpenseTypes(data);
      }
    )

    }, [parsedId]);

    const handleExpenseTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const inventoryTypeId = event.target.value;
        setExpense({...selectedExpense, expenseTypeId: inventoryTypeId});
        setHasBeenEdited(true);
    }

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = new Date(event.target.value);
        setExpense({...selectedExpense, date: date});

        setHasBeenEdited(true);
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const description = event.target.value;
        setExpense({...selectedExpense, description: description});
        setHasBeenEdited(true);
    }

    const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
        const quantity = parseFloat(event.target.value);
        setExpense({...selectedExpense, quantity: quantity});
        setHasBeenEdited(true);
    }

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        const amount = parseFloat(event.target.value);
        setExpense({...selectedExpense, amount: amount});
        setHasBeenEdited(true);
    }


    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            if (hasBeenEdited) {
              selectedExpense.updatedBy = systemUser.displayName;
              await postExpense(selectedExpense);
              navigate('/Expenses', {replace: true});
            }
        }
    
        setValidated(true);
      };

  
    return (
        <>
             {parsedId === "new" || expenseTypes.length > 0 ?
             <div>
             <h1>Edit Expense</h1>
                <div className='form-background'>
                <div className='form-container'>
                    <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                    <Form.Group className="mb-3" controlId="formExpenseType">
                        <Form.Label>Type</Form.Label>
                        <select className="form-select" aria-label="Class" onChange={handleExpenseTypeChange} required>
                            <option value="">Select a type</option>
                            {expenseTypes.map((expenseType) => (
                                <option key={expenseType.id} value={expenseType.id} selected={selectedExpense.expenseTypeId === expenseType.id}>{expenseType.name}</option>
                            ))}
                        </select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formDate">
                        <Form.Label>Date</Form.Label>
                        <Form.Control 
                        type="date" 
                        placeholder="Date" 
                        value={getCalendarDate(selectedExpense.date)} 
                        onChange={handleDateChange} 
                        onKeyDown={(e) => disableTyping(e)}
                        min={minDate}
                        max={maxDate}
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                        id="edit-description"
                        type="text"
                        placeholder="description"
                        onChange={handleDescriptionChange}
                        value={selectedExpense.description}
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formQuantity">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                        id="edit-quantity"
                        type="number"
                        step="any"
                        placeholder="Quantity"
                        onChange={handleQuantityChange}
                        value={selectedExpense.quantity}
                        min="1"
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formAmount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                        id="edit-amount"
                        type="number"
                        step="any"
                        placeholder="Amount"
                        onChange={handleAmountChange}
                        value={selectedExpense.amount}
                        min="0.1"
                        required
                        />
                    </Form.Group>


                    <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                        Save
                    </Button>
                    </Form>
                </div>
                </div>
             </div> : <Loading />}
        </>
  )
}

export default EditExpense;