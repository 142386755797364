import { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getExamFeeTermPayments, getStudent, getTermPeriod, postExamFeePayment } from '../../functions/fetchEntities';
import { getCalendarDate, maxDate, minDate } from '../../helpers/DateHelper';
import { TermPayment } from '../../types/TermFeePayment';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { ExamFeePaymentRequest } from '../../types/RequestModels/ExamFeePaymentRequest';
import { ExamFeePaymentMapper } from '../../mappers/ExamFeePaymentMapper';
import ReceiptComponent from '../ReceiptComponent';


function EditExamFeePage() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const { yearId, termId, studentId, paymentId } = useParams();

const parsedYearId = yearId !== undefined ? yearId : "";
const parsedTermId = termId !== undefined ? termId : "";
const parsedStudentId = studentId !== undefined ? studentId : "";
const parsedPaymentId = paymentId !== undefined || paymentId !== "new" ? paymentId : "";

//TODO: make sure IDs is not null
const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [examFeePayment, setExamFeePayment] = useState({
    id: "",
    paymentDate: new Date(),
    termId: parsedTermId,
    studentId: parsedStudentId,
    amountPaid: 0,
    receipt: null
}  as ExamFeePaymentRequest);
const [totalAmountPaid, setTotalAmountPaid] = useState(0);
const [totalAmountRequired, setTotalAmountRequired] = useState(0);

const [termDisplayName, setTermDisplayName] = useState("");
const [studentDisplayName, setStudentDisplayName] = useState("");

const navigate = useNavigate();

const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(event.target.value);
    setExamFeePayment({...examFeePayment, paymentDate: date});
    setHasBeenEdited(true);
}

const handleAmountPaidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amountPaid = parseFloat(event.target.value);
    setExamFeePayment({...examFeePayment, amountPaid: amountPaid});
    setHasBeenEdited(true);
}


const calculateTotalAmountPaid = (termPayments: TermPayment[]) => {
    let total = 0;
    termPayments.forEach((item: TermPayment) => {
        total += item.amountPaid;
    });

    return total;
}

const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;

    if(file){
        let reader = new FileReader(); 
        reader.readAsDataURL(file[0]);

        reader.onload = () => {
            setExamFeePayment({...examFeePayment, receipt: reader.result});
            setHasBeenEdited(true);
        }
    }
}


const handleSubmit = async (event:any) => {

    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }else{
        if(hasBeenEdited){
            examFeePayment.updatedBy = systemUser.displayName;
            postExamFeePayment(examFeePayment)
                .then(() => navigate(`/ExamFee/${parsedYearId}/${parsedTermId}/${parsedStudentId}`));
        }
    }

    setValidated(true);
  };

  
  useEffect(() => {

    const loadPage = async () => {

        const termFeePayments = (await getExamFeeTermPayments(parsedYearId, parsedStudentId)).map((item: any) => ExamFeePaymentMapper.mapExamFeePaymentToTermFeePaymentRequest(item));
        const termPeriod = await getTermPeriod(parsedTermId);
        const student = await getStudent(parsedStudentId);

        var sDate = new Date(termPeriod.startDate);
        var eDate = new Date(termPeriod.endDate);

        const termName = `${termPeriod.termName} (${sDate.getFullYear()}-${eDate.getFullYear()})`;

        const studentName = `${student.name} (${student.class.name})`;

        const totalPaid = calculateTotalAmountPaid(termFeePayments);
        setTotalAmountPaid(totalPaid);

        setTotalAmountRequired(student.class.yearlyFee);
        setTermDisplayName(termName.toString());
        setStudentDisplayName(studentName);

        const payment = termFeePayments.find((item: ExamFeePaymentRequest) => item.id === parsedPaymentId);  
        
        if(payment !== undefined){
            setExamFeePayment(ExamFeePaymentMapper.mapExamFeePaymentToTermFeePaymentRequest(payment));
        }
    }

     loadPage(); 
    }, [parsedPaymentId, parsedStudentId, parsedTermId, parsedYearId]);

  
    return (
        <>
            {
                <div>
                    <div>
                        <ul>
                            <li>{studentDisplayName} </li>
                            <li>{termDisplayName}</li>
                            <li>{totalAmountPaid}/{totalAmountRequired}</li>
                        </ul>
                    </div>

                    <div className='form-background'>
                        <div className='form-container'>
                            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)} >
                                <Form.Group className="mb-3" controlId="formDate">
                                    <Form.Label>Date Paid</Form.Label>
                                    <Form.Control 
                                    type="date" 
                                    placeholder="Date" 
                                    value={getCalendarDate(examFeePayment.paymentDate)} 
                                    onChange={handleDateChange} 
                                    min={minDate}
                                    max={maxDate}
                                    required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formPaid">
                                    <Form.Label>Amount Paid</Form.Label>
                                    <Form.Control 
                                    type="number"
                                    step="any" 
                                    placeholder="Amount Paid" 
                                    onChange={handleAmountPaidChange} 
                                    value={examFeePayment.amountPaid} 
                                    min="0.1"
                                    required
                                    />
                                </Form.Group>
                               
                               {
                                    examFeePayment.receipt === null &&
                                    <Form.Group className="mb-3" controlId="formFile">
                                    <Form.Label>Receipt</Form.Label>
                                        <Form.Control 
                                        type="file"
                                        onChange={handleFileChange} 
                                        accept='image/*'
                                        />
                                    </Form.Group>
                               }

                                <Button className='edit-form-submit' variant="primary" type="submit">
                                    Save
                                </Button>
                            </Form>
                        </div>
                    </div>

                    <ReceiptComponent receipt={examFeePayment.receipt?.toString()} returnUrl='/ExamFees' />
                </div>
                
            }
        </>
  )
}

export default EditExamFeePage;