import { TermPaymentRequest } from "../types/RequestModels/TermFeePaymentRequest";
import { TermPayment } from "../types/TermFeePayment";


export const TermFeePaymentMapper = {

    mapTermFeePaymentRequestToTermFeePayment: (termFeePaymentRequest: TermPaymentRequest) : TermPayment => {
        return {
            id: termFeePaymentRequest.id,
            paymentDate: termFeePaymentRequest.paymentDate,
            termId: termFeePaymentRequest.termId,
            studentId: termFeePaymentRequest.studentId,
            amountPaid: termFeePaymentRequest.amountPaid,
            receipt: termFeePaymentRequest.receipt,
            notes: termFeePaymentRequest.notes,
            student: termFeePaymentRequest.student,
            term: termFeePaymentRequest.term
        };
    },

    mapTermFeePaymentToTermFeePaymentRequest: (termFeePaymentToMap: any) : TermPaymentRequest => {
                
            return {
                id: termFeePaymentToMap.id,
                paymentDate: termFeePaymentToMap.paymentDate,
                termId: termFeePaymentToMap.termId,
                studentId: termFeePaymentToMap.studentId,
                amountPaid: termFeePaymentToMap.amountPaid,
                receipt: termFeePaymentToMap.receipt,
                notes: termFeePaymentToMap.notes,
                updatedBy: termFeePaymentToMap.updatedBy,
                student: termFeePaymentToMap.student,
                term: termFeePaymentToMap.term,
            };
        }


};


