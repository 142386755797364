// In your reducer file (path/to/reducers)
import { combineReducers } from '@reduxjs/toolkit';
import msalInstanceReducer  from './reduxActions';
import systemUserReducer  from './reduxActions';

const rootReducer = combineReducers({
  msalInstance: msalInstanceReducer,
  systemUser: systemUserReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
