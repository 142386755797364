export const getBooleanString = (value: boolean) => {
    return value ? "true" : "false";
}

export const getBooleanForAuth = (value: string | undefined) => {

    const result = value === undefined ? true : value.toLocaleLowerCase() === "true";

    return result;
}
