import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';
import { getCurrentTerm } from '../../helpers/TermTimeHelper';
// import { UniformSalesYear } from '../../types/UniformSalesYear';
import ExamFeesTable from './ExamFeesTable';
import { ExamFeesYear } from '../../types/ExamFeesYear';

function ExamFeesTabs(props:any) {

    var schoolFeesYear = props.props as ExamFeesYear;

  return (
    <Tabs defaultActiveKey={getCurrentTerm(schoolFeesYear.termPeriods)}>
        {
            schoolFeesYear.termPeriods && schoolFeesYear.termPeriods.map((termPeriod) => (
                <Tab eventKey={termPeriod.termName} title={termPeriod.termName} key={termPeriod.termName}>
                    <h1 className='centered'>{getDisplayDate(termPeriod.startDate)} - {getDisplayDate(termPeriod.endDate)}</h1>
                    <ExamFeesTable data={termPeriod} termYearId={schoolFeesYear.id} />
                </Tab>
            ))
        }
    </Tabs>
  )
}


export default ExamFeesTabs;