import { Tab, Tabs } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';
import { getCurrentTerm } from '../../helpers/TermTimeHelper';
import AccountTransactionsTable from './AccountTransactionsTable';
import { AccountTransactionYear } from '../../types/AccountTransactionYear';

function AccountTransactionsTabs(props:any) {

    var accountTransactionYear = props.props as AccountTransactionYear;

  return (
    <Tabs defaultActiveKey={getCurrentTerm(accountTransactionYear.termPeriods)}>
        {
            accountTransactionYear.termPeriods && accountTransactionYear.termPeriods.map((termPeriod) => (
                <Tab eventKey={termPeriod.termName} title={termPeriod.termName} key={termPeriod.termName}>
                    <h1 className='centered'>{getDisplayDate(termPeriod.startDate)} - {getDisplayDate(termPeriod.endDate)}</h1>
                    <AccountTransactionsTable data={termPeriod.items} />
                </Tab>
            ))
        }
    </Tabs>
  )
}


export default AccountTransactionsTabs;