import { useMsal } from "@azure/msal-react";
import { Nav } from 'react-bootstrap';

const SignInButton = () => {
    const { instance } = useMsal();

    const handleSignIn = () => {
        
      instance.loginPopup({
        scopes: ["user.read"]
      });
    }

    

  return (
    <Nav.Link href="/" onClick={handleSignIn}>
      Log in
    </Nav.Link>
  )
}

export default SignInButton;