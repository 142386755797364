import { InventorySalesItem } from "../types/InventorySalesYear";
import { InventoryItemSalesRequest } from "../types/RequestModels/InventoryItemSalesRequest";

export const InventorySalesItemMapper = {

    mapInventorySalesItemRequestToInventorySalesItem: (inventoryItemSalesRequest: InventoryItemSalesRequest) : InventorySalesItem => {
        return {
            id: inventoryItemSalesRequest.id,
            date: inventoryItemSalesRequest.date,
            quantity: inventoryItemSalesRequest.quantity,
            revenue: inventoryItemSalesRequest.revenue,
            studentId: inventoryItemSalesRequest.studentId,
            inventoryItemId: inventoryItemSalesRequest.inventoryItemId,
            inventoryItem: inventoryItemSalesRequest.inventoryItem,
            student: inventoryItemSalesRequest.student,
        };
    },

    mapInventorySalesItemToInventorySalesItemRequest: (inventorySalesItemToMap: any) : InventoryItemSalesRequest => {


            return {
                id: inventorySalesItemToMap.id,
                date: inventorySalesItemToMap.date,
                quantity: inventorySalesItemToMap.quantity,
                revenue: inventorySalesItemToMap.revenue,
                studentId: inventorySalesItemToMap.studentId,
                inventoryItemId: inventorySalesItemToMap.inventoryItemId,
                updatedBy: inventorySalesItemToMap.updatedBy,
                inventoryItem: inventorySalesItemToMap.inventoryItem,
                student: inventorySalesItemToMap.student,
                receipt: inventorySalesItemToMap.receipt
            };
        }

    };