import React, { useEffect, useState } from 'react'
import { getHealthCheck } from '../functions/fetchEntities';
import { Table } from 'react-bootstrap';


function HealthCheck() {

const [errors, setErrors] = useState([] as string[]);

  useEffect(() => {

    const getProperties = async () => {
        const errors = await getHealthCheck();

        console.log(errors);

        setErrors(errors);
    }

    getProperties();

    }, []);

  
    return (
        <>
          <Table striped hover responsive>
            <tbody>
                {errors.length > 0 ? (
                    errors.map((err: string, index: number) => (
                        <tr key={index}>
                            <td className='centered'>{err}</td> 
                        </tr>
                    ))
                ) : (
                    <tr className='no-class-row'>
                        <td colSpan={1}>No errors</td>
                    </tr>
                )}
            </tbody>
        </Table>
        </>
  )
}

export default HealthCheck;