import { AccountTransactionRequest } from "../types/RequestModels/AccountTransactionsRequest";


export const AccountTransactionMapper = {
    mapAccountTransactionRequestToAccountTransaction: (accountTransactionRequest: AccountTransactionRequest) => {
        return {

            id: accountTransactionRequest.id,
            accountName: accountTransactionRequest.accountName,
            date: accountTransactionRequest.date,
            reasonForTransaction: accountTransactionRequest.reasonForTransaction,
            amount: accountTransactionRequest.amount,
            transactionType: accountTransactionRequest.transactionType,
            updatedBy: accountTransactionRequest.updatedBy,
        };
    },
    mapAccountTransactionToAccountTransactionRequest: (accountTransactionToMap: any) => {
            
            return {
                id: accountTransactionToMap.id,
                accountName: accountTransactionToMap.accountName,
                date: accountTransactionToMap.date,
                reasonForTransaction: accountTransactionToMap.reasonForTransaction,
                amount: accountTransactionToMap.amount,
                transactionType: accountTransactionToMap.transactionType,
                updatedBy: accountTransactionToMap.updatedBy,
            };
        }
};


