import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from './authConfig';
import { getBooleanForAuth } from "./helpers/BooleanHelper";


const ppca = new PublicClientApplication(msalConfig);

// Boolean to control authentication
const authEnabled = getBooleanForAuth(process.env.REACT_APP_AUTH_ENABLED);


// Account selection logic is app dependent. Adjust as needed for different use cases.
// Set active account on page load
if (authEnabled) {
    const accounts = ppca.getAllAccounts();

    if (accounts.length > 0) {
        ppca.setActiveAccount(accounts[0]);
    }

    ppca.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            const account = event.payload.account;
            ppca.setActiveAccount(account);
        }
    });

    ppca.handleRedirectPromise().then((response) => {
        // Check if user signed in 
        const account = ppca.getActiveAccount();
        if (!account) {
            // Redirect anonymous user to login page 
            ppca.loginRedirect();
        }

    }).catch((error) => {
        console.log('error', error);
    });
}

export const retryGetAccount = async () => {
    if (!authEnabled) return null;

    ppca.loginRedirect();

    await ppca.handleRedirectPromise();

    const account = ppca.getActiveAccount();

    return account;
}

export const pca = ppca;
