import { Tab, Tabs } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';
import { getCurrentTerm } from '../../helpers/TermTimeHelper';
import InventorySalesTable from './InventorySalesTable';
import { InventoryItem } from '../../types/InventoryItem';
import { InventorySalesYear } from '../../types/InventorySalesYear';

function UniformSalesTabs(props:any) {

    var inventorySalesYear = props.selectedYear as InventorySalesYear;
    var inventoryTypes = props.inventoryTypes as InventoryItem[];

  return (
    <Tabs defaultActiveKey={getCurrentTerm(inventorySalesYear.termPeriods)}>
        {
            inventorySalesYear.termPeriods && inventorySalesYear.termPeriods.map((termPeriod) => (
                <Tab eventKey={termPeriod.termName} title={termPeriod.termName} key={termPeriod.termName}>
                    <h1 className='centered'>{getDisplayDate(termPeriod.startDate)} - {getDisplayDate(termPeriod.endDate)}</h1>
                    <InventorySalesTable data={termPeriod.items} inventoryTypes={inventoryTypes} />
                </Tab>
            ))
        }
    </Tabs>
  )
}


export default UniformSalesTabs;