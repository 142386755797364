import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getClasses, getScholarshipTypes, getStudent, postStudent } from '../../functions/fetchEntities';
import { getCalendarDate, maxDate, minDate, disableTyping } from '../../helpers/DateHelper';
import { Class } from '../../types/Class';
import { ScholarshipType } from '../../types/ScholarshipType';
import Loading from '../Loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { StudentRequest } from '../../types/RequestModels/StudentRequest';
import { StudentMapper } from '../../mappers/StudentMapper';
import { Parent } from '../../types/Student';




function EditStudent() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [parentValidated, setParentValidated] = useState(false);
const [selectedStudent, setSelectedStudent] = useState({dob: new Date(), parents: [] as Parent[]} as StudentRequest);
const [classes, setClasses] = useState([] as Class[]);
const [scholarshipTypes, setScholarshipTypes] = useState([] as ScholarshipType[]);

const [parent, setParent] = useState({ id:"", studentId: selectedStudent.id} as Parent);
const [showParentForm, setShowParentForm] = useState(false);

const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        getStudent(parsedId)
            .then((data) => setSelectedStudent(StudentMapper.mapStudentToStudentRequest(data)));
    }

    getClasses()
        .then((data) => setClasses(data));

    getScholarshipTypes()
        .then((data) => setScholarshipTypes(data));
        
    }, [parsedId]);

 
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setSelectedStudent({...selectedStudent, name: name});
        setHasBeenEdited(true);
    }

    const handleClassChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const classId = event.target.value;
        setSelectedStudent({...selectedStudent, classId: classId});
        setHasBeenEdited(true);
    }

    const handleDOBChange = (event: ChangeEvent<HTMLInputElement>) => {
        const dob = new Date(event.target.value);
        setSelectedStudent({...selectedStudent, dob: dob});
        setHasBeenEdited(true);
    }

    const handleDateLeftChange = (event: ChangeEvent<HTMLInputElement>) => {
        const dateLeft = new Date(event.target.value);
        setSelectedStudent({...selectedStudent, dateLeft: dateLeft});
        setHasBeenEdited(true);
    }

    const handleScholarshipTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const scholarshipType = event.target.value;
        setSelectedStudent({...selectedStudent, scholarshipType: scholarshipType});
        setHasBeenEdited(true);
    }

    const handleParentNameChange = (event: any) => {
        const name = event.target.value;
        setParent({...parent, name: name, studentId: selectedStudent.id});
        setHasBeenEdited(true);
    }

    const handlePhoneNumberChange = (event: any) => {
        const phoneNumber = event.target.value;
        setParent({...parent, phoneNumber: phoneNumber, studentId: selectedStudent.id});
        setHasBeenEdited(true);
    }


    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            if (hasBeenEdited) {
                selectedStudent.updatedBy = systemUser.displayName;
                await postStudent(selectedStudent);
            }
            navigate('/Students', {replace: true});
        }
        setValidated(true);
      };


      const handleParentSubmit = (event: any) => {

        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            var newParents = selectedStudent.parents;

            const parentID = newParents.length;
            parent.id = parentID.toString();
            newParents.push(parent);

            setSelectedStudent({...selectedStudent, parents: newParents});
            cancelParent();
        }
    
        setParentValidated(true);
    }


    const cancelParent = () => {
        setParent({...parent, name: "", phoneNumber: ""});
        setShowParentForm(false);
    }


    const handleDeleteParent = (id: string) => {
        const newParents = selectedStudent.parents.filter((p) => p.id !== id);
        setSelectedStudent({...selectedStudent, parents: newParents});
        setHasBeenEdited(true);
    }

  
    return (
        <>
             {parsedId === "new" || selectedStudent.id !== undefined ? 
                <div>
                    <h1>Edit Student</h1>
                    <div className='form-background'>
                    <div className='form-container'>
                        <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Student Name</Form.Label>
                            <Form.Control 
                            id="edit-name"
                            type="text" 
                            placeholder="Student Name" 
                            onChange={handleNameChange} 
                            value={selectedStudent.name} 
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formClass">
                            <Form.Label>Class</Form.Label>
                            <select id="edit-class" className="form-select" aria-label="Class" onChange={handleClassChange} required>
                                <option value="">Select a class</option>
                                {classes.map((classItem) => (
                                    <option key={classItem.id} value={classItem.id} selected={selectedStudent.classId === classItem.id}>{classItem.name}</option>
                                ))}
                            </select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formDob">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control 
                            id="edit-dob"
                            type="date" 
                            onChange={handleDOBChange} 
                            onKeyDown={(e) => disableTyping(e)}
                            value={getCalendarDate(selectedStudent.dob)} 
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formScholarshipType">
                            <Form.Label>Scholarship Type</Form.Label>
                            <select id="edit-scholarship-type" className="form-select" aria-label="Class" onChange={handleScholarshipTypeChange}>
                                <option value="">Select a scholarship type</option>
                                {scholarshipTypes.map((scholarshipType) => (
                                    <option value={scholarshipType.name} selected={selectedStudent.scholarshipType === scholarshipType.name}>{scholarshipType.name}</option>
                                ))}
                            </select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formDateLeft">
                            <Form.Label>Date Left</Form.Label>
                            <Form.Control 
                            id="edit-date-left"
                            type="date" 
                            onChange={handleDateLeftChange} 
                            onKeyDown={(e) => disableTyping(e)}
                            value={getCalendarDate(selectedStudent.dateLeft)} 
                            min={minDate}
                            max={maxDate}
                            />
                        </Form.Group>

                        {
                            selectedStudent && selectedStudent.parents.length > 0 &&
                            <div>
                                <h3 className='centered'>Parent Contact Information</h3>
                            <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Phone Number</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selectedStudent.parents.map((parent) => (
                                        <tr key={parent.id}>
                                            <td>{parent.name}</td>
                                            <td>{parent.phoneNumber}</td>
                                            <td>
                                                <Button className='delete-item-button' variant="danger" onClick={() => handleDeleteParent(parent.id)}>
                                                    X
                                                </Button>
                                            </td>       
                                        </tr>
                                    ))
                                }
                            </tbody>
                            </Table>
                            </div>
                        }


                        {!showParentForm && <Button className='edit-form-submit' variant="primary" onClick={() => setShowParentForm(true)}>
                            Add Parent Information
                        </Button>}

                        {!showParentForm && <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                            Save
                        </Button>}

                        </Form>

                        {showParentForm && 
                        <Form noValidate validated={parentValidated} onSubmit={event => handleParentSubmit(event)}>

                            <Form.Group className="mb-3" controlId="formParent">
                                <Form.Label>Parent Contact Information</Form.Label>
                                <Row>
                                        <Col>
                                        <Form.Control 
                                            placeholder="Name" 
                                            type='text'
                                            onChange={handleParentNameChange}
                                            value={parent.name}
                                            required
                                        />
                                    </Col>
                                    <Col>
                                    <Form.Control 
                                        placeholder="Phone Number"
                                        type='text'
                                        required
                                        value={parent.phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                        /> 
                                    </Col>
                                    <Col>
                                    <Button className='edit-form-submit' variant="primary" type="submit" >
                                        Enter
                                    </Button>
                                    <Button className='edit-form-submit' variant="primary" onClick={cancelParent}>
                                        Cancel
                                    </Button>
                                    </Col>    
                                </Row>
                            </Form.Group>
                        </Form>}
                    </div>
                    </div>
                </div> : <Loading />
             }
        </>
  )
}

export default EditStudent;