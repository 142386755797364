import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getBooleanString } from '../../../helpers/BooleanHelper';
import { getDisplayDate } from '../../../helpers/DateHelper';
import { PandCUniformSalesItem } from '../../../types/PandCUniformSalesYear';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { canEdit } from '../../../helpers/UserHelper';

function PandCUnformSalesTable(props: any) {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

    const sales = props.data as PandCUniformSalesItem[];

  return (
    <>
        <Table striped hover responsive>
            <thead>
                <tr>
                    <th>Date Paid</th>
                    <th>Date Received</th>
                    <th>Check Yards</th>
                    <th>Pink Yards</th>
                    <th>Student</th>
                    <th>Class</th>
                    <th>Paid in full</th>
                    <th>Received</th>
                    <th>Seamstress Paid</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {sales.length > 0 ? sales.map((item: PandCUniformSalesItem) => {
                    return (
                        <tr key={item.id}>
                            <td>{getDisplayDate(item.datePaid)}</td>
                            <td>{getDisplayDate(item.dateReceived)}</td>
                            <td>{item.checkYards}</td>
                            <td>{item.pinkYards}</td>
                            <td>{item.student.name}</td>
                            <td>{item.student.class.name}</td>
                            <td>{getBooleanString(item.paidInFull)}</td>
                            <td>{getBooleanString(item.received)}</td>
                            <td>{getBooleanString(item.seamstressPaid)}</td>
                            <td>
                                {
                                    canEdit(systemUser) &&
                                    <Link to={`/PandCUniformSale/${item.id}`} className="button">
                                        <Button>
                                            Edit
                                        </Button>
                                    </Link>
                                }
                            </td>
                        </tr>
                    )
                }) : (
                    <tr className='no-class-row'>
                        <td colSpan={10}>No uniform sales</td>
                    </tr>
                )}
            </tbody>
        </Table> 
    </>
  )
}

export default PandCUnformSalesTable;