import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { getSchoolFees } from '../../functions/fetchEntities';
import { SchoolFeesYear } from '../../types/SchoolFeesYear';
import Loading from '../Loading';
import SchholFeesTabs from './SchoolFeesTabs';

function SchoolFeesPage() {

const [schoolFees, setSchoolFeesYears] = useState([] as SchoolFeesYear[]);
const [selectedYear, setSelectedYear] = useState({} as SchoolFeesYear);

  useEffect(() => {

    const getProperties = async () => {
        const schoolFees = await getSchoolFees();

        setSchoolFeesYears(schoolFees);
        setSelectedYear(schoolFees[0]);
    }

    getProperties();

    }, []);


    const handleTermYearChange = (event: React.MouseEvent<HTMLElement>) => {
        const yearPeriod = (event.target as HTMLElement).innerText;

        const selectedYear = schoolFees.find((schoolFee) => schoolFee.periodName === yearPeriod);
        setSelectedYear(selectedYear as SchoolFeesYear);
    }
  
    return (
        <>
            {
            schoolFees ?
            <div>
                <div className="top-buttons">
                    <div className='select-year-btn'>
                        <Dropdown className="navitem">
                            <Dropdown.Toggle variant="success" id="selectYear">
                                {selectedYear.periodName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    schoolFees.map((schoolFee) => (
                                        <Dropdown.Item onClick={handleTermYearChange} key={schoolFee.periodName}>{schoolFee.periodName} </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                {selectedYear.termPeriods && <SchholFeesTabs props={selectedYear} />}
            </div> : <Loading />}
        </>
  )
}

export default SchoolFeesPage;