import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getClasses, getEnvironmentVariable } from '../../functions/fetchEntities';
import { Class } from '../../types/Class';
import Loading from '../Loading';
import { createRowTestId } from '../../helpers/StringHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function Classes() {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

  const [classes, setClasses] = useState<Class[]>([]);
  const [JHS3ID, setJHS3Id] = useState("");


  useEffect(() => {
    // fetch data
    getClasses().then((data) => { setClasses(data); })

    getEnvironmentVariable("JHS3_ID").then((data) => setJHS3Id(data));

  }, [])

  return (
    <>
        {
            canEdit(systemUser) &&
            <div className='add-new-class-btn'>
                    <Link className="navitem" to="/Class/new">
                        <Button variant="primary" className="mb-3">
                            Add Class
                        </Button>
                    </Link>
            </div>
        }

      {classes.length > 0 ?
      <div className='page-content'>
        <Table id='classes-table' striped hover responsive>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Pink and Check uniform price</th>
                    <th>Wednesday uniform price</th>
                    <th>Friday uniform price</th>
                    <th>Term Fee</th>
                    <th>Yearly exam fee</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {classes.map((item: Class) => {
                    
                    // JHS 3
                    const yearlyFee = item.id === JHS3ID ? item.yearlyFee : "";

                    return (
                        <tr id={createRowTestId(item.name)} className="classes-table-row" key={item.id}>
                            <td className="class-name">{item.name}</td>
                            <td className="pink-and-check-uniform">{item.pinkAndCheckUniformPrice}</td> 
                            <td className="wednesday-uniform">{item.wednesdayUniformPrice}</td> 
                            <td className="friday-uniform">{item.fridayUniformPrice}</td> 
                            <td className="term-fee">{item.termFee}</td>
                            <td className="yearly-fee">{yearlyFee}</td> 
                            <td>
                                {
                                    canEdit(systemUser) &&
                                    <Link to={`/Class/${item.id}`} className="button">
                                        <Button id={`${item.name}-btn`}>
                                            Edit
                                        </Button>
                                    </Link>
                                }
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
      </div> : <Loading />}
    </>
  )
}

export default Classes;