import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { deleteInventoryType, getInventoryType, postInventoryType } from '../../functions/fetchEntities';
import Loading from '../Loading';
import { InventoryTypeRequest } from '../../types/RequestModels/InventoryTypeRequest';
import { InventoryTypeMapper } from '../../mappers/InventoryTypeMapper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

function EditInventoryType() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [selectedInventoryType, setInventoryType] = useState({} as InventoryTypeRequest);
const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        getInventoryType(parsedId)
            .then((data) => setInventoryType(InventoryTypeMapper.mapInventoryTypeToInventoryTypeRequest(data)));
    }
        
    }, [parsedId]);

 
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setInventoryType({...selectedInventoryType, name: name});
        setHasBeenEdited(true);
    }

    const handleDelete = async () => {
        if (window.confirm(`Are you sure you want to delete ${selectedInventoryType.name}`)) {

            await deleteInventoryType(selectedInventoryType.id);
      
            alert("Inventory deleted");
            navigate('/Inventory', {replace: true});
          }
    }


    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            if (hasBeenEdited) {
                selectedInventoryType.updatedBy = systemUser.displayName;
                await postInventoryType(selectedInventoryType);
                navigate('/Inventory', {replace: true});
            }
        }
    
        setValidated(true);
      };

  
    return (
        <>
             {parsedId === "new" || selectedInventoryType.id ? 
             <div>
                <h1>Edit Inventory Type</h1>
        
                <div className='form-background'>
                <div className='form-container'>
                    <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>


                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control 
                        id="edit-name"
                        type="text" 
                        placeholder="Inventory Type Name" 
                        onChange={handleNameChange} 
                        value={selectedInventoryType.name} 
                        required
                        />
                    </Form.Group>


                    <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                        Save
                    </Button>
                    <Button className='edit-form-submit' variant="danger" onClick={handleDelete} >
                        Delete
                    </Button>
                    </Form>
                </div>
                </div>
            </div> : <Loading />}
        </>
  )
}

export default EditInventoryType;