import { LibraryBookRequest } from "../types/RequestModels/LibraryBookRequest";
import { LibraryBook } from "../types/LibraryBook";
import { LibraryBookRentalRequest } from "../types/RequestModels/LibraryBookRentalRequest";
import { LibraryBookRentalItem } from "../types/LibraryBookRentalYear";

export const LibraryMapper = {

    mapLibraryBookToLibraryBookRequest: (libraryBook: any): LibraryBookRequest => {
        return {
            id: libraryBook.id,
            title: libraryBook.title,
            author: libraryBook.author,
            quantity: libraryBook.quantity,

        } as LibraryBookRequest;
    },

    mapLibraryBookRequestToLibraryBook: (libraryBookRequest: LibraryBookRequest): LibraryBook => {
        return {
            id: libraryBookRequest.id,
            title: libraryBookRequest.title,
            author: libraryBookRequest.author,
            quantity: libraryBookRequest.quantity,

        } as LibraryBook;
    },

    mapLibraryBookRentalToLibraryBookRentalRequest: (libraryBookRental: any): LibraryBookRentalRequest => {

        return {
            id: libraryBookRental.id,
            libraryBookId: libraryBookRental.libraryBookId,
            libraryBook: libraryBookRental.libraryBook,
            studentId: libraryBookRental.studentId,
            student: libraryBookRental.student,
            rentedDate: libraryBookRental.rentedDate,
            expectedReturnDate: libraryBookRental.expectedReturnDate,
            actualReturnDate: libraryBookRental.actualReturnDate,
            notes: libraryBookRental.notes,

        } as LibraryBookRentalRequest;
    },

    mapLibraryBookRentalRequestToLibraryBookRental: (libraryBookRentalRequest: LibraryBookRentalRequest): LibraryBookRentalItem => {
        return {
            id: libraryBookRentalRequest.id,
            libraryBookId: libraryBookRentalRequest.libraryBookId,
            libraryBook: libraryBookRentalRequest.libraryBook,
            studentId: libraryBookRentalRequest.studentId,
            student: libraryBookRentalRequest.student,
            rentedDate: libraryBookRentalRequest.rentedDate,
            expectedReturnDate: libraryBookRentalRequest.expectedReturnDate,
            actualReturnDate: libraryBookRentalRequest.actualReturnDate,
            notes: libraryBookRentalRequest.notes,

        } as any;
    }

};