import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getLibraryBook, postLibraryBook } from '../../functions/fetchEntities';
import Loading from '../Loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { LibraryBookRequest } from '../../types/RequestModels/LibraryBookRequest';
import { LibraryMapper } from '../../mappers/LibraryMapper';

function EditLibraryBook() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [selectedLibraryBook, setLibraryBook] = useState({} as LibraryBookRequest);
const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        getLibraryBook(parsedId)
            .then((data) => setLibraryBook(LibraryMapper.mapLibraryBookToLibraryBookRequest(data)));
    }
        
    }, [parsedId]);

 
    const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const title = event.target.value;
        setLibraryBook({...selectedLibraryBook, title: title});
        setHasBeenEdited(true);
    }

    const handleAuthorChange = (event: ChangeEvent<HTMLInputElement>) => {
        const author = event.target.value;
        setLibraryBook({...selectedLibraryBook, author: author});
        setHasBeenEdited(true);
    }

    const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
        const quantity = event.target.value;
        setLibraryBook({...selectedLibraryBook, quantity: parseInt(quantity)});
        setHasBeenEdited(true);
    }


    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            if (hasBeenEdited) {
                selectedLibraryBook.updatedBy = systemUser.displayName;
                await postLibraryBook(selectedLibraryBook);
                navigate('/Library', {replace: true});

            }
        }
    
        setValidated(true);
      };

  
    return (
        <>
             {parsedId === "new" || selectedLibraryBook.id ? 
             <div>
                <h1>Edit library book</h1>
        
                <div className='form-background'>
                <div className='form-container'>
                    <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                    <Form.Group className="mb-3" controlId="formTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control 
                        id="edit-title"
                        type="text" 
                        placeholder="Book title" 
                        onChange={handleTitleChange} 
                        value={selectedLibraryBook.title} 
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formAuthor">
                        <Form.Label>Author</Form.Label>
                        <Form.Control 
                        id="edit-author"
                        type="text" 
                        placeholder="Book author" 
                        onChange={handleAuthorChange} 
                        value={selectedLibraryBook.author} 
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formQuantity">
                        <Form.Label>Total Quantity</Form.Label>
                        <Form.Control
                        id="edit-quantity" 
                        type="number"
                        placeholder="Quantity" 
                        onChange={handleQuantityChange} 
                        value={selectedLibraryBook.quantity} 
                        min="1"
                        required
                        />
                    </Form.Group>


                    <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                        Save
                    </Button>
                    </Form>
                </div>
                </div>
            </div> : <Loading />}
        </>
  )
}

export default EditLibraryBook;