import { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap';
import { getAuditLogs, getGenerateSpreadsheet } from '../../functions/fetchEntities';
import { AuditLog } from '../../types/AuditLog';
import Loading from '../Loading';
import { getDisplayDate } from '../../helpers/DateHelper';

function AuditLogs() {

  const [auditLogs, setAuditLogs] = useState([] as AuditLog[]);

  const generateAuditLogRow = (item: AuditLog) => {

    const changes = item.changes.split(";");

    return (
      <tr key={item.id} >
        <td>{getDisplayDate(item.date)}</td>
        <td>{item.type}</td>
        <td>
          <ul>
            {changes.map((change: string, index: number) => {

              return (
                <li key={`${index}`}>
                  {change}
                </li>
              )
            })}
          </ul>
        </td>
        <td>{item.updatedBy}</td>
    </tr>
  )

  }
  
  const generateSpreadsheet = async () => {
    
    getGenerateSpreadsheet();
    
    alert("https://drive.google.com/drive/folders/1GJmsoobHQQL17qdZPMRz4bbB_rzPzxy7");
  }
  


  useEffect(() => {
    // fetch data
    const loadPage = async () =>  {
        const data = await getAuditLogs();
        setAuditLogs(data);
    }

    loadPage();

  }, [])

  return (
    <>
      
      {auditLogs ?
      auditLogs.length > 0 ?
      <div>
          <div className='centered'>
            <br />
            <Button variant="primary" className="mb-3" onClick={generateSpreadsheet}>
                  Generate Spreadsheet
              </Button>
          </div>

          <Table striped hover responsive>
          <thead>
              <tr>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Changes</th>
                  <th>Updated By</th>
              </tr>
          </thead>
          <tbody>
              {auditLogs.map((item: AuditLog) => generateAuditLogRow(item))}
          </tbody>
          </Table> 
      </div>
      : <p>No audit logs found</p>
      : <Loading />}
    </>
  )
}

export default AuditLogs;