import { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getTermYear, postTermYear } from '../../functions/fetchEntities';
import { getCalendarDate, minDate, disableTyping } from '../../helpers/DateHelper';
import { TermPeriod, TermYear } from '../../types/TermYear';
import Loading from '../Loading';

// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";


function EditTermYears() {

const [validated, setValidated] = useState(false);
const [selectedTermYear, setSelectedTermYear] = useState({termPeriods: [{termName: "Term 1"}, {termName: "Term 2"}, {termName: "Term 3"}] as TermPeriod[]} as TermYear);
const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        getTermYear(parsedId)
            .then((data) => setSelectedTermYear(data));
    }
        
    }, [parsedId]);


    const handleTermOneStartDateChange = (event: any) => {
        const date = new Date(event.target.value);

        selectedTermYear.termPeriods[0].startDate = date;

        setSelectedTermYear({...selectedTermYear});
    }

    const handleTermOneEndDateChange = (event: any) => {
        const date = new Date(event.target.value);

        selectedTermYear.termPeriods[0].endDate = date;

        setSelectedTermYear({...selectedTermYear});
    }

    const handleTermTwoStartDateChange = (event: any) => {
        const date = new Date(event.target.value);

        selectedTermYear.termPeriods[1].startDate = date;

        setSelectedTermYear({...selectedTermYear});
    }

    const handleTermTwoEndDateChange = (event: any) => {
        const date = new Date(event.target.value);

        selectedTermYear.termPeriods[1].endDate = date;

        setSelectedTermYear({...selectedTermYear});
    }

    const handleTermThreeStartDateChange = (event: any) => {
        const date = new Date(event.target.value);

        selectedTermYear.termPeriods[2].startDate = date;

        setSelectedTermYear({...selectedTermYear});
    }

    const handleTermThreeEndDateChange = (event: any) => {
        const date = new Date(event.target.value);

        selectedTermYear.termPeriods[2].endDate = date;

        setSelectedTermYear({...selectedTermYear});
    }


    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            await postTermYear(selectedTermYear);
            navigate('/TermYears', {replace: true});
        }
    
        setValidated(true);
      };

  
    return (
        <>
             {parsedId === "new" ||selectedTermYear.id !== undefined ?
             <div>
             <h1>Edit Term Year</h1>
     
             <div className='form-background'>
             <div className='form-container'>
                 <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                <h2>TERM 1</h2>

                <Form.Group className="mb-3" controlId="formDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control 
                    id='edit-term-one-start'
                    type="date" 
                    placeholder="Date" 
                    value={ selectedTermYear.termPeriods ? getCalendarDate(selectedTermYear.termPeriods[0].startDate) : ""} 
                    onChange={handleTermOneStartDateChange} 
                    onKeyDown={(e) => disableTyping(e)}
                    min={minDate}
                    required
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control 
                    id='edit-term-one-end'
                    type="date" 
                    placeholder="Date" 
                    value={ selectedTermYear.termPeriods ? getCalendarDate(selectedTermYear.termPeriods[0].endDate) : ""} 
                    onChange={handleTermOneEndDateChange} 
                    onKeyDown={(e) => disableTyping(e)}
                    min={minDate}
                    required
                    />
                </Form.Group> 

                <h2>TERM 2</h2>

                <Form.Group className="mb-3" controlId="formDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control 
                    id='edit-term-two-start'
                    type="date" 
                    placeholder="Date" 
                    value={ selectedTermYear.termPeriods ? getCalendarDate(selectedTermYear.termPeriods[1].startDate) : ""} 
                    onChange={handleTermTwoStartDateChange} 
                    onKeyDown={(e) => disableTyping(e)}
                    min={minDate}
                    required
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control 
                    id='edit-term-two-end'
                    type="date" 
                    placeholder="Date" 
                    value={ selectedTermYear.termPeriods ? getCalendarDate(selectedTermYear.termPeriods[1].endDate) : ""} 
                    onChange={handleTermTwoEndDateChange} 
                    onKeyDown={(e) => disableTyping(e)}
                    min={minDate}
                    required
                    />
                </Form.Group>


                <h3>TERM 3</h3>

                <Form.Group className="mb-3" controlId="formDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control 
                    id='edit-term-three-start'
                    type="date" 
                    placeholder="Date" 
                    value={ selectedTermYear.termPeriods ? getCalendarDate(selectedTermYear.termPeriods[2].startDate) : ""} 
                    min={minDate}
                    onChange={handleTermThreeStartDateChange} 
                    onKeyDown={(e) => disableTyping(e)}
                    required
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control 
                    id='edit-term-three-end'
                    type="date" 
                    placeholder="Date" 
                    value={ selectedTermYear.termPeriods ? getCalendarDate(selectedTermYear.termPeriods[2].endDate) : ""} 
                    onChange={handleTermThreeEndDateChange} 
                    onKeyDown={(e) => disableTyping(e)}
                    min={minDate}
                    required
                    />
                </Form.Group> 

                 <Button id='save' className='edit-form-submit' variant="primary" type="submit">
                     Save
                 </Button>
                 </Form>
             </div>
             </div>
          </div> : <Loading />}
        </>
  )
}

export default EditTermYears;