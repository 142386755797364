import { useIsAuthenticated } from '@azure/msal-react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import SignInButton from './SignInButton';
import SignOutButton from './SignOutButton';
import logo from './Marinel-1.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { getBooleanForAuth } from '../../helpers/BooleanHelper';

function NavLinks() {

  const authEnabled = getBooleanForAuth(process.env.REACT_APP_AUTH_ENABLED);

  const isAuthenticated = useIsAuthenticated() || authEnabled;
  const state = useSelector((state: RootState) => state.systemUser);
  const systemUser = state.systemUser;

  return (
    <Container>
      <Navbar.Brand href="/"><img className="site-logo" src={logo} alt="Marinel logo" /></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        {
          isAuthenticated && (
            <Nav className="me-auto">
            <NavDropdown title="Personnel" id="collasible-nav-dropdown">
                
                <NavDropdown.Item href="/Students">
                  Students
                </NavDropdown.Item>
                 <NavDropdown.Item href="/Staff">
                  Staff
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Fees" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/NewAdmission">
                    New Admission
                </NavDropdown.Item>
                <NavDropdown.Item href="/SchoolFees">
                    School Fees
                </NavDropdown.Item>
                <NavDropdown.Item href="/ExamFees">
                    Exam Fees
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/ExtraClasses">
                  Extra Classes
                </NavDropdown.Item>
                <NavDropdown.Item href="/FeedingSales">
                  Feeding Sales
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="/BookSales">
                  Book Sales
                </NavDropdown.Item> */}
                <NavDropdown.Divider />
                {systemUser.jobTitle === "SuperUser" && (
                  <NavDropdown.Item href="/Report">
                    Report
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item href="/AccountTransactions">
                  Accounts
                </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Uniforms" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/UniformSales">
                    Uniform
                </NavDropdown.Item>
                <NavDropdown.Item href="/PandCUniformSales">
                    Pink and Check
                </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/Library">Library</Nav.Link>

            <NavDropdown title="Inventory" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/Inventory">
                Inventory 
              </NavDropdown.Item>
              <NavDropdown.Item href="/InventorySales">
                Inventory Sales
              </NavDropdown.Item>
              <NavDropdown.Item href="/Expenses">
                Expenses
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="School Information" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="/Classes">
                  Classes
                </NavDropdown.Item>
                <NavDropdown.Item href="/TermYears">
                  Terms
                </NavDropdown.Item>
            </NavDropdown>
            {systemUser.jobTitle === "SuperUser" && (
              <NavDropdown title="Settings" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/Users">
                System Users
              </NavDropdown.Item>
              <NavDropdown.Item href="/SystemHistory">
                System History
              </NavDropdown.Item>
            </NavDropdown>
            )}
            
          </Nav>
          )
        }
        <Nav className="ml-auto">
        {isAuthenticated ? <SignOutButton user={systemUser.displayName} /> : <SignInButton />}
        </Nav>
      </Navbar.Collapse>
    </Container>
  )
}

export default NavLinks;