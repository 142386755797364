import React from 'react'
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getDisplayDate } from '../../helpers/DateHelper';
import { AccountTransactionItem } from '../../types/AccountTransactionYear';
import { createButtonTestId, createRowTestId } from '../../helpers/StringHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function AccountTransactionsTable(data: any) {

    
    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;


    var transactions = data.data;

  return (
    <Table striped hover responsive>
      <thead>
          <tr>
              <th>Transaction Date</th>
              <th>Account Name</th>
              <th>Amount</th>
              <th>Notes</th>
              <th></th>
          </tr>
      </thead>
      <tbody>
          {transactions && transactions.length > 0 ? (
          
          transactions.map((item: AccountTransactionItem) => (
              <tr id={createRowTestId(`${getDisplayDate(item.date)} ${item.accountName} ${item.amount}`)} key={item.id}>
                  <td className='item-date'>{getDisplayDate(item.date)}</td>
                  <td className='item-account-name'>{item.accountName}</td>
                  <td className='item-amount'>{item.amount}</td>
                  <td className='item-notes'>{item.reasonForTransaction}</td>
                  <td>
                      {
                        canEdit(systemUser) &&
                        <Link to={`/AccountTransaction/${item.id}`} className="button">
                            <Button id={createButtonTestId(`${getDisplayDate(item.date)} ${item.accountName} ${item.amount}`)}>
                                Edit
                            </Button>
                        </Link>
                      }
                  </td>
              </tr>
          )
          )) : (
                <tr className='no-class-row'>
                    <td colSpan={5}>No transactions</td>
                </tr>
          )}
      </tbody>
      </Table>
  )
}

export default AccountTransactionsTable;