import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';
import { getCurrentTerm } from '../../helpers/TermTimeHelper';
import ReportTable from './ReportTable';
import { ReportYear } from '../../types/ReportYear';

function ReportTabs(props:any) {

    var reportYear = props.props as ReportYear;

  return (
    <Tabs defaultActiveKey={getCurrentTerm(reportYear.termPeriods)}>
        {
            reportYear.termPeriods && reportYear.termPeriods.map((termPeriod) => (
                <Tab eventKey={termPeriod.termName} title={termPeriod.termName} key={termPeriod.termName}>
                    <h1 className='centered'>{getDisplayDate(termPeriod.startDate)} - {getDisplayDate(termPeriod.endDate)}</h1>
                    <ReportTable data={termPeriod.items} />
                </Tab>
            ))
        }
    </Tabs>
  )
}


export default ReportTabs;