import { useEffect, useState } from 'react'
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getLibraryBookRentals, getLibraryBooks } from '../../functions/fetchEntities';
import Loading from '../Loading';
import { LibraryBook } from '../../types/LibraryBook';
import { isBookAvailable, noOfBooksAvailable } from '../../helpers/LibaryHelper';
import { LibraryBookRentalItem, LibraryBookRentalTermPeriod, LibraryBookRentalYear } from '../../types/LibraryBookRentalYear';
import LibraryRentalPage from './LibraryRentalPage';
import { createButtonTestId, createRowTestId } from '../../helpers/StringHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function Library() {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

  const [libraryBooks, setLibraryBooks] = useState<LibraryBook[]>([]);
  const [libraryBookRentals, setLibraryBookRentals] = useState<LibraryBookRentalItem[]>([]);
  const [defaultActiveKey, setDefaultActiveKey] = useState("Books");

  // map librarybook year to list of librarybookrentalitem
  const mapLibraryBookRentals = (libraryBooksYears: LibraryBookRentalYear[]) => {

    let allYears = [] as LibraryBookRentalYear[];
    let allTerms = [] as LibraryBookRentalTermPeriod[];
    let allLibraryBookRentals = [] as LibraryBookRentalItem[];

    //Get all the library book rentals from all years

    libraryBooksYears.forEach((u: LibraryBookRentalYear) => {
        allYears.push(u);
        u.termPeriods.forEach((t: LibraryBookRentalTermPeriod) => {
            allTerms.push(t);
            t.items.forEach((i: LibraryBookRentalItem) => {
                allLibraryBookRentals.push(i);
            })
        })
    })

    return allLibraryBookRentals;

}



  useEffect(() => {
    // fetch data
    getLibraryBooks().then((data) => {
        setLibraryBooks(data);
    })

    getLibraryBookRentals().then((data) => {
        setLibraryBookRentals(mapLibraryBookRentals(data));
    })

    const hashFragment = window.location.hash;

    if (hashFragment === '#Rentals') {
        setDefaultActiveKey("Rentals");
    }

  }, [])



  return (
  <>
  {
    canEdit(systemUser) &&
    <div className='add-new-class-btn'>
        <Link className="navitem" to="/LibraryBook/new">
            <Button variant="primary" className="mb-3">
                Add library book
            </Button>
        </Link>
    </div>
  }
 

     {libraryBooks ?
     <div className='page-content'>
        
        <Tabs defaultActiveKey={defaultActiveKey}>
            <Tab eventKey={"Books"} title={"Books"} >
                {
                    libraryBooks.length > 0 ?
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Author</th>
                                <th>Total Quantity</th>
                                <th>Total Available</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {libraryBooks.map((item: LibraryBook) => (
                                <tr id={createRowTestId(item.title)} className='book-row' key={item.id}>
                                    <td className='item-title'>{item.title}</td>
                                    <td className='item-author'>{item.author}</td>
                                    <td className='item-quantity'>{item.quantity}</td>
                                    <td className='item-amount-available'>{noOfBooksAvailable(item, libraryBookRentals)}</td>
                                    <td>
                                        {isBookAvailable(item, libraryBookRentals) ? <Link to={`/LibraryBookRental/${item.id}/new`} className="button">
                                        {
                                            canEdit(systemUser) &&
                                            <Button id={`${createButtonTestId(item.title)}-rent`}>
                                                Rent
                                            </Button>
                                        }
                                    </Link> : null}
                                    
                                    </td>
                                    <td>
                                        {
                                            canEdit(systemUser) &&
                                            <Link to={`/LibraryBook/${item.id}`} className="button">
                                                <Button id={createButtonTestId(item.title)}>
                                                    Edit
                                                </Button>
                                            </Link>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table> 
                 : 
                 <p>No library books</p>
                 }
                
            </Tab>
            <Tab eventKey={"Rentals"} title={"Rentals"} >
               <LibraryRentalPage />
            </Tab>
        </Tabs>
            
    </div> : <Loading />}
  </>
  )
}

export default Library;