import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getAccountTransaction, getAccountTransactionTypes, getReceipts, postAccountTransaction,  } from '../../functions/fetchEntities';
import Loading from '../Loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { AccountTransactionRequest } from '../../types/RequestModels/AccountTransactionsRequest';
import { AccountTransactionMapper } from '../../mappers/AccountTransactionMapper';
import { disableTyping, getCalendarDate, maxDate, minDate } from '../../helpers/DateHelper';
import ReceiptComponent from '../ReceiptComponent';

function EditAccountTransaction() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [selectedTransaction, setSelectedTransaction] = useState({date: new Date()} as AccountTransactionRequest);
const [transactionTypes, setTransactionTypes] = useState<string[]>([]);

const [receipts, setReceipts] = useState<string[] | undefined>(undefined); 
const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        getAccountTransaction(parsedId)
            .then((data) => {
                setSelectedTransaction(AccountTransactionMapper.mapAccountTransactionToAccountTransactionRequest(data));
            });
    }

    getAccountTransactionTypes()
        .then(data => setTransactionTypes(data));

    getReceipts(selectedTransaction.transactionType, selectedTransaction.date)
        .then(r => setReceipts(r));
        
    // eslint-disable-next-line
    }, [parsedId]);

    const handleAccountChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const accountName = event.target.value;
        setSelectedTransaction({...selectedTransaction, accountName: accountName});
        setHasBeenEdited(true);
    }

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = new Date(event.target.value);

        setSelectedTransaction({...selectedTransaction, date: date});

        getReceipts(selectedTransaction.transactionType, date)
            .then(r => setReceipts(r));

        setHasBeenEdited(true);
    }

    const handleReasonChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const reason = event.target.value;
        setSelectedTransaction({...selectedTransaction, reasonForTransaction: reason});
        setHasBeenEdited(true);
    }

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        const amount = parseFloat(event.target.value);
        setSelectedTransaction({...selectedTransaction, amount: amount});
        setHasBeenEdited(true);
    }

    const handleTransactionTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {

        const transactionType = event.target.value;
        setSelectedTransaction({...selectedTransaction, transactionType: transactionType});

        getReceipts(transactionType, selectedTransaction.date)
            .then(r => setReceipts(r));

        setHasBeenEdited(true);
    }

    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            
            if (hasBeenEdited) {
                selectedTransaction.updatedBy = systemUser.displayName;
                await postAccountTransaction(selectedTransaction);
                navigate('/AccountTransactions', {replace: true});
             }
        }
    
        setValidated(true);
    };
  
    return (
        <>
             {parsedId === "new" || selectedTransaction.id ? 
             <div>
                <h1 className='centered'>Edit Account Transaction</h1>
    
                <div className='form-background'>
                    <div className='form-container'>
                        <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                        <Form.Group className="mb-3" controlId="formTransactionType">
                            <Form.Label>Transaction Type</Form.Label>
                            <select id="edit-account-transaction-type" className="form-select" aria-label="Account" onChange={handleTransactionTypeChange} required>
                                <option value="">Select a type</option>
                                {
                                    transactionTypes.map(type => {
                                        return (
                                            <option value={type} selected={selectedTransaction.transactionType === type}>{type}</option>
                                        );
                                    })
                                }
                            </select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formAccount">
                            <Form.Label>Account Name</Form.Label>
                            <select id="edit-account" className="form-select" aria-label="Account" onChange={handleAccountChange} required>
                                <option value="">Select an account</option>
                                <option value="Account 1" selected={selectedTransaction.accountName === "Account 1"}>Account 1</option>
                                <option value="Account 2" selected={selectedTransaction.accountName === "Account 2"}>Account 2</option>
                                <option value="Account 3" selected={selectedTransaction.accountName === "Account 3"}>Account 3</option>
                            </select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Date</Form.Label>
                            <Form.Control 
                            type="date" 
                            placeholder="Date" 
                            value={getCalendarDate(selectedTransaction.date)} 
                            onChange={handleDateChange} 
                            onKeyDown={(e) => disableTyping(e)}
                            min={minDate}
                            max={maxDate}
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formAmounts">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control 
                            id="edit-amount"
                            type="number" 
                            step="any"
                            onChange={handleAmountChange} 
                            value={selectedTransaction.amount} 
                            min="0.1"
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formNoted">
                            <Form.Label>Notes</Form.Label>
                            <textarea id="edit-notes" className="form-control" rows={3} onChange={handleReasonChange} value={selectedTransaction.reasonForTransaction} />
                        </Form.Group>

                        <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                            Save
                        </Button>
                        </Form>
                    </div>
                </div>

                <div>
                    {
                        receipts !== undefined ?
                        receipts.map(r => {
                            return (<ReceiptComponent receipt={r?.toString()} returnUrl='/AccountTransactions'  />)
                        }) : <Loading />
                    }
                </div>
             </div> : <Loading />}
        </>
  )
}

export default EditAccountTransaction;