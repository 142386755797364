import React from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';
import { getCurrentTerm } from '../../helpers/TermTimeHelper';
import { ClassFeeYear } from '../../types/ClassFeeYear';
import ClassFeeTable from './ClassFeeTable';

function ClassFeeTabs(props:any) {

    var classFeeYear = props.props as ClassFeeYear;

  return (
    <Tabs defaultActiveKey={getCurrentTerm(classFeeYear.termPeriods)}>
        {
            classFeeYear.termPeriods && classFeeYear.termPeriods.map((termPeriod) => (
                <Tab eventKey={termPeriod.termName} title={termPeriod.termName} key={termPeriod.termName}>
                    <h1 className='centered'>{getDisplayDate(termPeriod.startDate)} - {getDisplayDate(termPeriod.endDate)}</h1>
                    <ClassFeeTable data={termPeriod.items} />
                </Tab>
            ))
        }
    </Tabs>
  )
}


export default ClassFeeTabs;