import { PandCUniformSalesItemRequest } from "../types/RequestModels/PandCUniformSalesItemRequest";
import { PandCUniformSalesItem } from "../types/PandCUniformSalesYear";

export const PandCUniformSalesItemMapper = {

    mapUniformSalesItemRequestToUniformSalesItem: (uniformSalesItemRequest: PandCUniformSalesItemRequest) : PandCUniformSalesItem => {
        return {
            id: uniformSalesItemRequest.id,
            datePaid: uniformSalesItemRequest.datePaid,
            dateReceived: uniformSalesItemRequest.dateReceived,
            checkYards: uniformSalesItemRequest.checkYards,
            pinkYards: uniformSalesItemRequest.pinkYards,
            paidInFull: uniformSalesItemRequest.paidInFull,
            received: uniformSalesItemRequest.received,
            seamstressPaid: uniformSalesItemRequest.seamstressPaid,
            revenue: uniformSalesItemRequest.revenue,
            studentId: uniformSalesItemRequest.studentId,
            student: uniformSalesItemRequest.student,
        } as PandCUniformSalesItem;
    },

    mapUniformSalesItemToUniformSalesItemRequest: (uniformSalesItem: any) : PandCUniformSalesItemRequest => {
        return {
            id: uniformSalesItem.id,
            datePaid: uniformSalesItem.datePaid,
            dateReceived: uniformSalesItem.dateReceived,
            checkYards: uniformSalesItem.checkYards,
            pinkYards: uniformSalesItem.pinkYards,
            paidInFull: uniformSalesItem.paidInFull,
            received: uniformSalesItem.received,
            seamstressPaid: uniformSalesItem.seamstressPaid,
            revenue: uniformSalesItem.revenue,
            studentId: uniformSalesItem.studentId,
            student: uniformSalesItem.student,
            receipt: uniformSalesItem.receipt
        } as PandCUniformSalesItemRequest;

    }
};