import { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getClasses, getInventoryItemsByTypeId, getInventorySaleItem, getInventoryTypes, getStudenstByClassId, postInventoryItemSale } from '../../functions/fetchEntities';
import { Class } from '../../types/Class';
import { InventoryItem } from '../../types/InventoryItem';
import { Student } from '../../types/Student';
import Loading from '../Loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { InventoryType } from '../../types/InventoryType';
import { InventoryItemSalesRequest } from '../../types/RequestModels/InventoryItemSalesRequest';
import { InventorySalesItemMapper } from '../../mappers/InventoryItemSalesMapper';
import { disableTyping, getCalendarDate, maxDate, minDate } from '../../helpers/DateHelper';
import ReceiptComponent from '../ReceiptComponent';

function EditInventorySalesPage() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [itemSale, setItemSale] = useState({ date: new Date()} as InventoryItemSalesRequest);
const [inventoryTypes, setInventoryTypes] = useState<InventoryType[]>([]);
const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
const [classes, setClasses] = useState([] as Class[]);
const [students, setStudents] = useState([] as Student[]);
const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    const getProperties = async () => {
        const inventorySale = await getInventorySaleItem(parsedId);
        setItemSale(InventorySalesItemMapper.mapInventorySalesItemToInventorySalesItemRequest(inventorySale));

        getStudenstByClassId(inventorySale.student.classId)
            .then((data) => setStudents(data));

    }

    if (parsedId !== "new") {
        getProperties();        
    }

    getInventoryTypes()
        .then((data) => {

            var newTypes = data.filter(ut => ut.name !== "Uniform" && ut.name !== "Material");

            setInventoryTypes(newTypes)
        });

    getClasses()
        .then((data) => setClasses(data));
        
    }, [parsedId]);

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = new Date(event.target.value);
        setItemSale({...itemSale, date: date});

        setHasBeenEdited(true);
    }

    const handleQuantityChange = (event: any) => {
        const quantity = event.target.value;

        const itemType = inventoryItems.find((item) => item.id === itemSale.inventoryItemId);

        if (!itemType) {
            setItemSale({...itemSale, quantity: quantity});
        }else{

            const totalAvailable = itemType.quantity as number;

            if (quantity > totalAvailable) {
                alert("Quantity cannot be greater than total available: " + totalAvailable);
                setItemSale({...itemSale, quantity: 0});
            }else{
                setItemSale({...itemSale, quantity: quantity});
            }
        }

        setHasBeenEdited(true);
    }

    const handleClassChange = (event: any) => {
        const classId = event.target.value;

        getStudenstByClassId(classId)
            .then((data) => setStudents(data));
    }

    const handleStudentChange = (event: any) => {
        const studentId = event.target.value;

        setItemSale({...itemSale, studentId: studentId});
        setHasBeenEdited(true);
    }

    const handleItemTypeChange = (event: any) => {
        const typeId = event.target.value;

        getInventoryItemsByTypeId(typeId)
            .then((data) => setInventoryItems(data));
    }

    const handleItemChange = (event: any) => {
        const itemTypeId = event.target.value;

        setItemSale({...itemSale, inventoryItemId: itemTypeId});
        setHasBeenEdited(true);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files;
    
        if(file){
            let reader = new FileReader(); 
            reader.readAsDataURL(file[0]);
    
            reader.onload = () => {

                setItemSale({...itemSale, receipt: reader.result});
                setHasBeenEdited(true);
            }
        }
    }

    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{

            if (hasBeenEdited) {

                const itemType = inventoryItems.find((item) => item.id === itemSale.inventoryItemId);

                const totalAvailable = itemType?.quantity as number;

                if (itemSale.quantity > totalAvailable) {
                    alert("Quantity cannot be greater than total available: " + totalAvailable);
                    setItemSale({...itemSale, quantity: 0});
                }else{
                    itemSale.updatedBy = systemUser.displayName;

                    await postInventoryItemSale(itemSale);
                    navigate('/InventorySales', {replace: true});
                }
            }
            
        }
    
        setValidated(true);
      };

  
    return (
        <>
            { parsedId === "new"  || itemSale.id ?
             <div>
                <h1>Edit Inventory Sale</h1>
                { parsedId !== "new" ? <h4>{itemSale.inventoryItem.name}</h4> : null }
        
                <div className='form-background'>
                    <div className='form-container'>
                        <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                        <Form.Group className="mb-3" controlId="formDate">
                            <Form.Label>Date</Form.Label>
                            <Form.Control 
                            type="date" 
                            placeholder="Date" 
                            value={getCalendarDate(itemSale.date)} 
                            onChange={handleDateChange} 
                            onKeyDown={(e) => disableTyping(e)}
                            min={minDate}
                            max={maxDate}
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Class</Form.Label>
                            <select className="form-select" aria-label="Classes" onChange={handleClassChange} required>
                                <option value="">Select a class</option>
                                {classes.map((classItem) => (
                                    <option key={classItem.id} 
                                    value={classItem.id} 
                                    selected={itemSale.student?.classId === classItem.id}
                                    >
                                        {classItem.name}
                                    </option>
                                ))}
                            </select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Student</Form.Label>
                            <select className="form-select" aria-label="Students" onChange={handleStudentChange} required>
                                <option value="">Select a student</option>
                                {students.map((student) => (
                                    <option key={student.id} 
                                    value={student.id} 
                                    selected={itemSale.student?.id === student.id}
                                    >
                                        {student.name}
                                    </option>
                                ))}
                            </select>
                        </Form.Group>

                        { parsedId === "new" ? 
                            <div>
                                <Form.Group className="mb-3">
                                    <Form.Label>Inventory Type</Form.Label>
                                    <select className="form-select" aria-label="Inventory Type" onChange={handleItemTypeChange} required>
                                        <option value="">Select a inventory type</option>
                                        {inventoryTypes.map((itemType) => (
                                            <option 
                                            key={itemType.id} 
                                            value={itemType.id} 
                                            >
                                                {itemType.name}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Group> 

                                <Form.Group className="mb-3">
                                    <Form.Label>Inventory Items</Form.Label>
                                    <select className="form-select" aria-label="Inventory Items" onChange={handleItemChange} required>
                                        <option value="">Select a inventory item</option>
                                        {inventoryItems.map((item) => (
                                            <option 
                                            key={item.id} 
                                            value={item.id} 
                                            disabled={item.quantity === 0}
                                            >
                                                {item.name}{item.quantity === 0 ? " (Out of stock)" : ""}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Group> 
                            </div>  
                        : null }

                        <Form.Group className="mb-3">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control 
                            id="edit-quantity"
                            type="number"
                            placeholder="Quantity" 
                            onChange={handleQuantityChange} 
                            value={itemSale.quantity} 
                            min="0.1"
                            step="any"
                            required
                            />
                        </Form.Group>

                        {
                            (itemSale.receipt === null || itemSale.receipt === undefined) &&
                            <Form.Group className="mb-3">
                            <Form.Label>Receipt</Form.Label>
                                <Form.Control 
                                type="file"
                                onChange={handleFileChange} 
                                accept='image/*'
                                />
                            </Form.Group>
                        }

                        <Button className='edit-form-submit' variant="primary" type="submit">
                            Save
                        </Button>
                        </Form>
                    </div>

                    <ReceiptComponent receipt={itemSale.receipt?.toString()} returnUrl={`/InventorySales`} />

                </div>
            </div> : <Loading />}
        </>
  )
}



export default EditInventorySalesPage;