import { BookSaleItemRequest } from "../types/RequestModels/BookSaleItemRequest";


export const BookSaleItemRequestMapper = {

    mapBookSaleItemRequestToBookSaleItem: (bookSaleItemRequest: BookSaleItemRequest) => {
        return {

            id: bookSaleItemRequest.id,
            date: bookSaleItemRequest.date,
            pricePerBook: bookSaleItemRequest.pricePerBook,
            noOfBooksSold: bookSaleItemRequest.noOfBooksSold,
            revenue: bookSaleItemRequest.revenue,
            inventoryItemId: bookSaleItemRequest.inventoryItemId,
            inventoryItem: bookSaleItemRequest.inventoryItem,
            updatedBy: bookSaleItemRequest.updatedBy,
        };
    },


    mapBookSaleItemToBookSaleItemRequest: (bookSaleItemToMap: any) => {

            return {
                id: bookSaleItemToMap.id,
                date: bookSaleItemToMap.date,
                pricePerBook: bookSaleItemToMap.pricePerBook,
                noOfBooksSold: bookSaleItemToMap.noOfBooksSold,
                revenue: bookSaleItemToMap.revenue,
                inventoryItemId: bookSaleItemToMap.inventoryItemId,
                inventoryItem: bookSaleItemToMap.inventoryItem,
                updatedBy: bookSaleItemToMap.updatedBy,

            };
        }
};


