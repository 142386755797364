import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getFeedingItems } from '../../functions/fetchEntities';
import { FeedingSaleYear } from '../../types/FeedingSaleYear';
import Loading from '../Loading';
import FeedingSaleTabs from './FeedingSaleTabs';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function FeedingSalesPage() {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

    const [feedingSales, setFeedingSale] = useState([] as FeedingSaleYear[]);
    const [selectedYear, setSelectedYear] = useState({} as FeedingSaleYear);

  useEffect(() => {

    const getProperties = async () => {
        const feedingSales = await getFeedingItems();

        setFeedingSale(feedingSales);
        setSelectedYear(feedingSales[0]);
    }

    getProperties();

    }, []);


    const handleTermYearChange = (event: React.MouseEvent<HTMLElement>) => {
        const yearPeriod = (event.target as HTMLElement).innerText;

        const selectedYear = feedingSales.find((feedingSaleYear) => feedingSaleYear.periodName === yearPeriod);
        setSelectedYear(selectedYear as FeedingSaleYear);
    }
  
    return (
        <>
            {feedingSales.length > 0 ?
            <div>
            <div className="top-buttons">
                <div className='select-year-btn'>
                    <Dropdown className="navitem">
                        <Dropdown.Toggle variant="success" id="selectYear">
                            {selectedYear.periodName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {feedingSales.map((feedingSaleYear) => (
                                <Dropdown.Item onClick={handleTermYearChange} key={feedingSaleYear.periodName}>{feedingSaleYear.periodName} </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
    
                {
                    canEdit(systemUser) &&
                    <div className='add-new-class-btn'>
                        <Link className="navitem" to="/FeedingSale/new">
                            <Button variant="primary" className="mb-3">
                                Add Feeding Sale
                            </Button>
                        </Link>
                    </div>
                }
            </div>
            {selectedYear.termPeriods && <FeedingSaleTabs props={selectedYear} />}
        </div> : <Loading />}
        </>
  )
}

export default FeedingSalesPage;