import { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getClasses, getStudenstByClassId, getUniformItemTypes, getUniformSale, postUniformSale } from '../../functions/fetchEntities';
import { Class } from '../../types/Class';
import { InventoryItem } from '../../types/InventoryItem';
import { Student } from '../../types/Student';
import Loading from '../Loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { UniformSalesItemRequest } from '../../types/RequestModels/UniformSalesItemRequest';
import { UniformSalesItemMapper } from '../../mappers/UniformSalesItemMapper';
import { disableTyping, getCalendarDate, maxDate, minDate } from '../../helpers/DateHelper';
import ReceiptComponent from '../ReceiptComponent';

function EditUniformSalesPage() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [uniformSale, setUniformSale] = useState({ date: new Date()} as UniformSalesItemRequest);
const [uniformItemTypes, setUniformItemTypes] = useState([] as InventoryItem[]);
const [classes, setClasses] = useState([] as Class[]);
const [students, setStudents] = useState([] as Student[]);
const navigate = useNavigate();


const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  
  useEffect(() => {

    const getProperties = async () => {
        const uniformSale = await getUniformSale(parsedId);
        setUniformSale(UniformSalesItemMapper.mapUniformSalesItemToUniformSalesItemRequest(uniformSale));

        getStudenstByClassId(uniformSale.student.classId)
            .then((data) => setStudents(data));
    }

    if (parsedId !== "new") {

        getProperties();        
    }

    getUniformItemTypes()
        .then((data) => setUniformItemTypes(data));

    getClasses()
        .then((data) => setClasses(data));
        
    }, [parsedId]);

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = new Date(event.target.value);
        setUniformSale({...uniformSale, date: date});

        setHasBeenEdited(true);
    }

    const handleQuantityChange = (event: any) => {
        const quantity = event.target.value;

        const uniformItemType = uniformItemTypes.find((item) => item.id === uniformSale.inventoryItemId);

        if (!uniformItemType) {
            setUniformSale({...uniformSale, quantity: quantity});
        }else{

            const totalAvailable = uniformItemType.quantity as number;

            if (quantity > totalAvailable) {
                alert("Quantity cannot be greater than total available: " + totalAvailable);
                setUniformSale({...uniformSale, quantity: 0});
            }else{
                setUniformSale({...uniformSale, quantity: quantity});
            }
        }

        setHasBeenEdited(true);
    }

    const handlePaidInFullChange = (event: any) => {
        const paidInFull = (event.target.value === "true");
        setUniformSale({...uniformSale, paidInFull: paidInFull});
        setHasBeenEdited(true);
    }

    const handleReceivedChange = (event: any) => {
        const received = (event.target.value === "true");
        setUniformSale({...uniformSale, received: received});
        setHasBeenEdited(true);
    }

    const handleUniformTypeChange = (event: any) => {
        const uniformTypeId = event.target.value;

        setUniformSale({...uniformSale, inventoryItemId: uniformTypeId});
        setHasBeenEdited(true);
    }

    const handleClassChange = (event: any) => {
        const classId = event.target.value;

        getStudenstByClassId(classId)
            .then((data) => setStudents(data));
    }

    const handleStudentChange = (event: any) => {
        const studentId = event.target.value;

        setUniformSale({...uniformSale, studentId: studentId});
        setHasBeenEdited(true);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files;
    
        if(file){
            let reader = new FileReader(); 
            reader.readAsDataURL(file[0]);
    
            reader.onload = () => {

                setUniformSale({...uniformSale, receipt: reader.result});
                setHasBeenEdited(true);
            }
        }
    }

    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{

            if (hasBeenEdited) {

                const uniformItemType = uniformItemTypes.find((item) => item.id === uniformSale.inventoryItemId);

                const totalAvailable = uniformItemType?.quantity as number;

                if (uniformSale.quantity > totalAvailable) {
                    alert("Quantity cannot be greater than total available: " + totalAvailable);
                    setUniformSale({...uniformSale, quantity: 0});
                }else{
                    uniformSale.updatedBy = systemUser.displayName;

                    await postUniformSale(uniformSale);
                    navigate('/UniformSales', {replace: true});
                }
            }
            
        }
    
        setValidated(true);
      };

  
    return (
        <>
            { parsedId === "new"  || uniformSale.id ?
             <div>
                <h1>Edit Uniform Sale</h1>
        
                <div className='form-background'>
                <div className='form-container'>
                    <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                    <Form.Group className="mb-3">
                            <Form.Label>Date</Form.Label>
                            <Form.Control 
                            type="date" 
                            placeholder="Date" 
                            value={getCalendarDate(uniformSale.date)} 
                            onChange={handleDateChange} 
                            onKeyDown={(e) => disableTyping(e)}
                            min={minDate}
                            max={maxDate}
                            required
                            />
                        </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Class</Form.Label>
                        <select className="form-select" aria-label="Classes" onChange={handleClassChange} required>
                            <option value="">Select a class</option>
                            {classes.map((classItem) => (
                                <option key={classItem.id} 
                                value={classItem.id} 
                                selected={uniformSale.student?.classId === classItem.id}
                                >
                                    {classItem.name}
                                </option>
                            ))}
                        </select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Student</Form.Label>
                        <select className="form-select" aria-label="Students" onChange={handleStudentChange} required>
                            <option value="">Select a student</option>
                            {students.map((student) => (
                                <option key={student.id} 
                                value={student.id} 
                                selected={uniformSale.student?.id === student.id}
                                >
                                    {student.name}
                                </option>
                            ))}
                        </select>
                    </Form.Group>

                    { parsedId === "new" ?

                        <Form.Group className="mb-3">
                            <Form.Label>Uniform Type</Form.Label>
                            <select className="form-select" aria-label="Uniform Type" onChange={handleUniformTypeChange} required>
                                <option value="">Select a uniform type</option>
                                {uniformItemTypes.map((uniformType) => (
                                    <option 
                                    key={uniformType.id} 
                                    value={uniformType.id} 
                                    selected={uniformSale.inventoryItemId === uniformType.id}
                                    disabled={uniformType.quantity === 0}>
                                        {uniformType.name}{uniformType.quantity === 0 ? " (Out of stock)" : ""}
                                    </option>
                                ))}
                            </select>
                        </Form.Group> 
                    : null }

                    <Form.Group className="mb-3">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control 
                        id="edit-quantity"
                        type="number"
                        placeholder="Quantity" 
                        onChange={handleQuantityChange} 
                        value={uniformSale.quantity} 
                        min="1"
                        required
                        />
                    </Form.Group>

                    {
                        (uniformSale.receipt === null || uniformSale.receipt === undefined) &&
                        <Form.Group className="mb-3">
                        <Form.Label>Receipt</Form.Label>
                            <Form.Control 
                            type="file"
                            onChange={handleFileChange} 
                            accept='image/*'
                            />
                        </Form.Group>
                    }

                    <Form.Group className="mb-3">
                        <Form.Label>Paid in full</Form.Label>
                        <Form.Check id="paidInFull-yes" label="true" value="true" name="paidInFull" type='radio' onChange={handlePaidInFullChange} checked={uniformSale.paidInFull} required/>
                        <Form.Check id="paidInFull-no" label="false" value="false" name="paidInFull" type='radio' onChange={handlePaidInFullChange} checked={!uniformSale.paidInFull} required />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Received</Form.Label>
                        <Form.Check id="received-yes" label="true" value="true" name="received" type='radio' onChange={handleReceivedChange} checked={uniformSale.received} required/>
                        <Form.Check id="received-no" label="false" value="false" name="received" type='radio' onChange={handleReceivedChange} checked={!uniformSale.received} required />
                    </Form.Group>

                    <Button className='edit-form-submit' variant="primary" type="submit">
                        Save
                    </Button>
                    </Form>
                </div>


                <ReceiptComponent receipt={uniformSale.receipt?.toString()} returnUrl='/UniformSales' />
                
                </div>
            </div> : <Loading />}
        </>
  )
}

export default EditUniformSalesPage;