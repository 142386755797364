import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getUniformItemTypes, getUniformSales } from '../../functions/fetchEntities';
import { UniformSalesYear } from '../../types/UniformSalesYear';
import Loading from '../Loading';
import UniformSalesTabs from './UniformSalesTabs';
import { InventoryItem } from '../../types/InventoryItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function UniformSalesPage() {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

const [uniformSales, setUniformSales] = useState([] as UniformSalesYear[]);
const [selectedYear, setSelectedYear] = useState({} as UniformSalesYear);
const [uniformTypes, setUniformTypes] = useState([] as InventoryItem[]);

  useEffect(() => {

    const getProperties = async () => {
        const uniformSales = await getUniformSales();
        const uniformTypes = await getUniformItemTypes();

        setUniformSales(uniformSales);
        setSelectedYear(uniformSales[0]);

        setUniformTypes(uniformTypes);
    }

    getProperties();

    }, []);


    const handleTermYearChange = (event: React.MouseEvent<HTMLElement>) => {
        const yearPeriod = (event.target as HTMLElement).innerText;

        const selectedYear = uniformSales.find((uniformFeeYear) => uniformFeeYear.periodName === yearPeriod);
        setSelectedYear(selectedYear as UniformSalesYear);
    }
  
    return (
        <>
            {uniformSales.length > 0 ?
            <div>
                <div className="top-buttons">
                    <div className='select-year-btn'>
                        <Dropdown className="navitem">
                            <Dropdown.Toggle variant="success" id="selectYear">
                                {selectedYear.periodName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {uniformSales.map((uniformSaleYear) => (
                                    <Dropdown.Item onClick={handleTermYearChange} key={uniformSaleYear.periodName}>{uniformSaleYear.periodName} </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    {
                        canEdit(systemUser) &&
                        <div className='add-new-class-btn'>
                            <Link className="navitem" to="/UniformSale/new">
                                <Button variant="primary" className="mb-3">
                                    Add uniform sale
                                </Button>
                            </Link>
                        </div>

                    }
        
                    
                </div>
                {selectedYear.termPeriods && <UniformSalesTabs selectedYear={selectedYear} uniformTypes={uniformTypes} />}
            </div> : <Loading />}
        </>
  )
}

export default UniformSalesPage;