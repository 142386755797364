import { ClassRequest } from "../types/RequestModels/ClassRequest";

export const ClassMapper = {
    mapClassRequestToClass: (classRequest: ClassRequest) => {
        return {
            id: classRequest.id,
            name: classRequest.name,
            pinkAndCheckUniformPrice: classRequest.pinkAndCheckUniformPrice,
            wednesdayUniformPrice: classRequest.wednesdayUniformPrice,
            fridayUniformPrice: classRequest.fridayUniformPrice,
            termFee: classRequest.termFee,
            students: classRequest.students,
            yearlyFee: classRequest.yearlyFee
        };
    },
    mapClassToClassRequest: (classToMap: any) => {

        return {
            id: classToMap.id,
            name: classToMap.name,
            pinkAndCheckUniformPrice: classToMap.pinkAndCheckUniformPrice,
            wednesdayUniformPrice: classToMap.wednesdayUniformPrice,
            fridayUniformPrice: classToMap.fridayUniformPrice,
            termFee: classToMap.termFee,
            students: classToMap.students,
            updatedBy: classToMap.updatedBy,
            yearlyFee: classToMap.yearlyFee
        };
    }

};

