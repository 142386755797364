import { useEffect, useState } from 'react'
import { Accordion, Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getClasses, getEnvironmentVariable, getInventory, getPandCUniformSales, getUniformSales, postStudent } from '../../functions/fetchEntities';
import { Class } from '../../types/Class';
import { Student } from '../../types/Student';
import Loading from '../Loading';
import { UniformSalesItem, UniformSalesTermPeriod, UniformSalesYear } from '../../types/UniformSalesYear';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { StudentRequest } from '../../types/RequestModels/StudentRequest';
import { PandCUniformSalesItem, PandCUniformSalesTermPeriod, PandCUniformSalesYear } from '../../types/PandCUniformSalesYear';
import { canEdit } from '../../helpers/UserHelper';

function NewAdmission() {

  const state = useSelector((state: RootState) => state.systemUser);
  const systemUser = state.systemUser;

  const PANDCUNIFORMNAME = 'PANDC UNIFORM';

  const [classes, setClasses] = useState<Class[]>([]);
  const [uniformSales, setUniformSales] = useState([] as UniformSalesItem[]);
  const [pAndCUniformSales, setPandCUniformSales] = useState([] as PandCUniformSalesItem[]);
  const [obligatoryUniformTypes, setObligatoryUniformTypes] = useState([PANDCUNIFORMNAME] as string[]);

  const navigate = useNavigate();

  const hasPaidFirstTerm = (student: Student) => {

    const firstPayment = student.schoolTermFeePayments[0];

    if (firstPayment !== undefined) {

      const termID = firstPayment.termId;
      const allTermPayments = student.schoolTermFeePayments.filter(p => p.termId === termID);
      const totalPaid = allTermPayments.reduce((total, payment) => total + payment.amountPaid, 0);

      var classTermFee = classes.find(c => c.id === student.classId)?.termFee;

      if (classTermFee && totalPaid >= classTermFee) {
        return "Yes";
      }
    }

    return "No";
  }

  const filterUniformSales = (uniformSalesYears: UniformSalesYear[]) : UniformSalesItem[]  => {

    let allYears = [] as UniformSalesYear[];
    let allTerms = [] as UniformSalesTermPeriod[];
    let allUniformSales = [] as UniformSalesItem[];

    //Get all the uniform sales from all years

    uniformSalesYears.forEach((u: UniformSalesYear) => {
      allYears.push(u);
      u.termPeriods.forEach((t: UniformSalesTermPeriod) => {
        allTerms.push(t);
        t.items.forEach((i: UniformSalesItem) => {
          allUniformSales.push(i);
        });
      });
    });

    return allUniformSales;
  }

  const filterPandCUniformSales = (uniformSalesYears: PandCUniformSalesYear[]) : PandCUniformSalesItem[]  => {

    let allYears = [] as PandCUniformSalesYear[];
    let allTerms = [] as PandCUniformSalesTermPeriod[];
    let allUniformSales = [] as PandCUniformSalesItem[];

    //Get all the uniform sales from all years

    uniformSalesYears.forEach((u: PandCUniformSalesYear) => {
      allYears.push(u);
      u.termPeriods.forEach((t: PandCUniformSalesTermPeriod) => {
        allTerms.push(t);
        t.items.forEach((i: PandCUniformSalesItem) => {
          allUniformSales.push(i);
        });
      });
    });

    return allUniformSales;
  }

  const hasPaidForUniform = (student: Student) => {



    const uniformSalesItems = uniformSales.filter(u => u.studentId === student.id && u.paidInFull === true);
    const pAndCSalesItems = pAndCUniformSales.filter(u => u.studentId === student.id && u.paidInFull === true);

    //TODO: Add the rest of the uniform types
    let ownedUniformTypes = [] as string[];

    if (pAndCSalesItems.length > 0) {
      ownedUniformTypes.push(PANDCUNIFORMNAME);
    }

    uniformSalesItems.forEach((u: UniformSalesItem) => {
      ownedUniformTypes.push(u.inventoryItem.name);
    });

    let uniqueArray = ownedUniformTypes.filter((value, index, array) => array.indexOf(value) === index);

    let checker = (arr: string[], target:string[]) => target.every(v => arr.includes(v));

    if (checker(uniqueArray, obligatoryUniformTypes)) {
      return "Yes";
    }

    return "No";
  }

  const handleNewAdmission = async (student: StudentRequest) => {

    if (window.confirm(`Confirm payment for ${student.name}`)) {

      student.hasPaidNewAdmissionFormFee = true;
      student.updatedBy = systemUser.displayName;

      //TODO: temp fix, workout why parents[] comes back as undefined
      student.parents = student.parents ?? [];
      
      await postStudent(student);

      //TODO: it should only confirm payment if it has indeed been updated. also change student.hasPaidNewAdmissionFormFee = false
      alert("Payment confirmed");
      navigate('/NewAdmission', {replace: true});
    }

  }

  useEffect(() => {

    // fetch data
    const getProperties = async () => {
        const classes = await getClasses();
        const uniformSales = await getUniformSales();
        const inventoryItems = await getInventory();
        const pAndCuniformSales = await getPandCUniformSales();
        const uniformTypeId = await getEnvironmentVariable("UNIFORM_TYPE_ID");

        //Uniforms
        const uniformTypes = inventoryItems.filter(i => i.inventoryTypeId === uniformTypeId);

        var allUniformTypes = uniformTypes.map(u => u.name);
        allUniformTypes.push(PANDCUNIFORMNAME);

        setObligatoryUniformTypes(allUniformTypes);

        setClasses(classes);
        setUniformSales(filterUniformSales(uniformSales));
        setPandCUniformSales(filterPandCUniformSales(pAndCuniformSales));
    }

    getProperties();
  }, [])

  return (
    <>
        {classes.length > 0 ?

        <Accordion defaultActiveKey={classes.map(c => c.name)} alwaysOpen>
          {classes.map((item: Class) => (
              <Accordion.Item key={item.id} eventKey={item.name}>
                  <Accordion.Header>{item.name}</Accordion.Header>
                  <Accordion.Body>
                    {item.students.length > 0 ? <Table striped hover responsive>
                      <thead>
                          <tr>
                              <th>Name</th>
                              <th>Has paid for first term</th>
                              <th>Has paid for all uniform</th>
                              <th>Has paid new admission form</th>
                          </tr>
                      </thead>
                      <tbody>
                          {item.students.map((s: Student) => {

                            const student = s as StudentRequest;

                            return (
                                <tr key={student.id}>
                                  <td>{student.name}</td>
                                  <td>{hasPaidFirstTerm(student)}</td>
                                  <td>{hasPaidForUniform(student)}</td>
                                  <td>
                                    {
                                      student.hasPaidNewAdmissionFormFee ? <span>Yes</span> : canEdit(systemUser) ? <Button onClick={e => handleNewAdmission(student)}>Confirm Payment</Button> : <span>No</span>}</td>
                                </tr>
                                )
                          })}
                      </tbody>
                    </Table> : <div>No students in this class</div>}
                  </Accordion.Body>
              </Accordion.Item>
              ))}
        </Accordion> : <Loading /> }

    </>
  )
}

export default NewAdmission;