import { FeedingSaleItemRequest } from "../types/RequestModels/FeedingSaleItemRequest";


export const FeedingSaleItemMapper = {

    mapFeedingSaleItemRequestToFeedingSaleItem: (feedingSaleItemRequest: FeedingSaleItemRequest) => {
        return {

            id: feedingSaleItemRequest.id,
            date: feedingSaleItemRequest.date,
            numberOfStudents: feedingSaleItemRequest.numberOfStudents,
            numberOfStudentsPaid: feedingSaleItemRequest.numberOfStudentsPaid,
            totalCollected: feedingSaleItemRequest.totalCollected,
            revenue: feedingSaleItemRequest.revenue,
            studentsWhoHaventPaid: feedingSaleItemRequest.studentsWhoHaventPaid,
            feedingExpenses: feedingSaleItemRequest.feedingExpenses,
            updatedBy: feedingSaleItemRequest.updatedBy,
        };
    },


    mapFeedingSaleItemToFeedingSaleItemRequest: (feedingSaleItemToMap: any) => {

        return {
            id: feedingSaleItemToMap.id,
            date: feedingSaleItemToMap.date,
            numberOfStudents: feedingSaleItemToMap.numberOfStudents,
            numberOfStudentsPaid: feedingSaleItemToMap.numberOfStudentsPaid,
            totalCollected: feedingSaleItemToMap.totalCollected,
            revenue: feedingSaleItemToMap.revenue,
            studentsWhoHaventPaid: feedingSaleItemToMap.studentsWhoHaventPaid,
            feedingExpenses: feedingSaleItemToMap.feedingExpenses,
            updatedBy: feedingSaleItemToMap.updatedBy,

        };
    }

};


