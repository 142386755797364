import { graphConfig, loginRequest, redirectUri } from "../authConfig";
import { AccountInfo, AuthenticationResult, PublicClientApplication } from "@azure/msal-browser";
import { SystemUser } from "../types/SystemUser";
import { retryGetAccount } from "../authActions";
import { getBooleanForAuth } from "../helpers/BooleanHelper";

const authEnabled = getBooleanForAuth(process.env.REACT_APP_AUTH_ENABLED);

export const RequestAccessToken = async (instance: PublicClientApplication) => {

    if (!authEnabled) return "";

    let activeAccount : AccountInfo | null = instance.getAllAccounts()[0];
    
    if(!activeAccount){
        activeAccount = await retryGetAccount() as AccountInfo;
    }

    const request = {
        ...loginRequest,
        account: activeAccount
    };

    let response = {} as AuthenticationResult;
    let accesssToken = "";

    try {

        response = await instance.acquireTokenSilent(request);
        accesssToken = response.accessToken;
        
    } catch (error) {
        console.log(error);

        response = await instance.acquireTokenPopup(request);
        accesssToken = response.accessToken;
    }

    return accesssToken;
}

const getHeaders = (accessToken: string) => {
    const headers = new Headers();
    const BearToken = `Bearer ${accessToken}`;
    headers.append("Authorization", BearToken);
    headers.append("Content-Type", "application/json");

    return headers;
}

const getMSGraph = async (endpoint:string, accessToken: string) => {

    const options = {
        method: "GET",
        headers: getHeaders(accessToken),
    }

    const response = await fetch(endpoint, options);

    return response.json();
}

const patchMSGraph = async (endpoint:string, accessToken: string, content:any) => {

    const options = {
        method: "PATCH",
        headers: getHeaders(accessToken),
        body: JSON.stringify(content)
    }

    await fetch(endpoint, options);
}

const postMSGraph = async (endpoint:string, accessToken: string, content:any) => {

    const options = {
        method: "POST",
        headers: getHeaders(accessToken),
        body: JSON.stringify(content)
    }

    const response = await fetch(endpoint, options)
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        return data;
    })
    .catch((error) => {
        console.log(error);
    });

    return response;
}

const deleteMSGraphUser = async (endpoint:string, accessToken: string) => {

    const options = {
        method: "DELETE",
        headers: getHeaders(accessToken)
    }

    const response = await fetch(endpoint, options)
    .catch((error) => {
        console.log(error);
    });

    return response;
}

export const fetchUsers = async (accessToken:string) => {

  const users = await getMSGraph(graphConfig.graphUsersEndpoint, accessToken)

  return users.value as SystemUser[];
}

export const fetchLoggedInUser = async (accessToken:string) => {

    if (!authEnabled) return {};
    
    const users = await getMSGraph(graphConfig.graphMeEndpoint, accessToken)
  
    return users;
}

export const fetchUser = async (userId:string, accessToken:string) => {

    const user = await getMSGraph(`${graphConfig.graphUsersEndpoint}/${userId}`, accessToken)
  
    return user as SystemUser;
}

export const updateSystemUser = async (id:string, accessToken: string, user: SystemUser) => {
    await patchMSGraph(`${graphConfig.graphUsersEndpoint}/${id}`, accessToken, user);
};

export const createSystemUser = async (accessToken: string, user: SystemUser) => {

    const content = {
        invitedUserEmailAddress: user.mail,
        inviteRedirectUrl: redirectUri,
        sendInvitationMessage: true,
      }

    const result = await postMSGraph(graphConfig.graphInvitationsEndpoint, accessToken, content)

    await updateSystemUser(result.invitedUser.id, accessToken, user);

    await updatePermissions(accessToken, result.invitedUser);

};

export const updatePermissions = async (accessToken: string, user: SystemUser) => {

    const content = {
        "@odata.type": "#microsoft.graph.unifiedRoleAssignment",
        "principalId": user.id,
        "roleDefinitionId": graphConfig.graphDirectoryWriterPermissionID,
        "directoryScopeId": "/" 
    }

    await postMSGraph(graphConfig.graphRoleAssignmentsEndpoint, accessToken, content)
};

export const deleteSystemUser = async (id:string, accessToken: string) => {
    await deleteMSGraphUser(`${graphConfig.graphUsersEndpoint}/${id}`, accessToken);
};
