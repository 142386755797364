import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getPandCUniformSales } from '../../../functions/fetchEntities';
import { PandCUniformSalesYear } from '../../../types/PandCUniformSalesYear';
import Loading from '../../Loading';
import PandCUniformSalesTabs from './PandCUniformSalesTabs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { canEdit } from '../../../helpers/UserHelper';

function PandCUniformSalesPage() {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

const [uniformSales, setUniformSales] = useState([] as PandCUniformSalesYear[]);
const [selectedYear, setSelectedYear] = useState({} as PandCUniformSalesYear);

  useEffect(() => {

    const getProperties = async () => {
        const uniformSales = await getPandCUniformSales();

        setUniformSales(uniformSales);
        setSelectedYear(uniformSales[0]);
    }

    getProperties();

    }, []);


    const handleTermYearChange = (event: React.MouseEvent<HTMLElement>) => {
        const yearPeriod = (event.target as HTMLElement).innerText;

        const selectedYear = uniformSales.find((uniformFeeYear) => uniformFeeYear.periodName === yearPeriod);
        setSelectedYear(selectedYear as PandCUniformSalesYear);
    }
  
    return (
        <>
            <div className="top-buttons">
                <div className='select-year-btn'>
                    <Dropdown className="navitem">
                        <Dropdown.Toggle variant="success" id="selectYear">
                            {selectedYear.periodName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {uniformSales.map((uniformSaleYear) => (
                                <Dropdown.Item onClick={handleTermYearChange} key={uniformSaleYear.periodName}>{uniformSaleYear.periodName} </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                {
                    canEdit(systemUser) &&
                    <div className='add-new-class-btn'>
                        <Link className="navitem" to="/PandCUniformSale/new">
                            <Button variant="primary" className="mb-3">
                                Add uniform sale
                            </Button>
                        </Link>
                    </div>
                }
                
            </div>
            {uniformSales.length > 0 ?
            <div>
                {selectedYear.termPeriods && <PandCUniformSalesTabs selectedYear={selectedYear} />}
            </div> : <Loading />}
        </>
  )
}

export default PandCUniformSalesPage;