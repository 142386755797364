import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import NavbarComp from './components/navbar/NavbarComp'

import { MsalProvider } from "@azure/msal-react";
import { useEffect } from 'react';
import { PublicClientApplication } from "@azure/msal-browser";
import { RequestAccessToken, fetchLoggedInUser } from './functions/fetchGraph';
import { useDispatch } from 'react-redux';
import { setMsalInstance, setSystemUser } from './reduxActions';


function App({ msalInstance }: any) {

  const dispatch = useDispatch();


 useEffect(() => {

  const instance = msalInstance as PublicClientApplication;

  const loadPage = async () => {
    const accessCode = await RequestAccessToken(instance);
    const user = await fetchLoggedInUser(accessCode);

    dispatch(setSystemUser(user));
    dispatch(setMsalInstance(msalInstance));
  }

  loadPage();
 });

  return (
    <MsalProvider instance={msalInstance} >
      <div className="App">
        <NavbarComp />
      </div>
    </MsalProvider>
  );
}

export default App;