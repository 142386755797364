import { UniformSalesItemRequest } from "../types/RequestModels/UniformSalesItemRequest";
import { UniformSalesItem } from "../types/UniformSalesYear";



export const UniformSalesItemMapper = {

    mapUniformSalesItemRequestToUniformSalesItem: (uniformSalesItemRequest: UniformSalesItemRequest) : UniformSalesItem => {
        return {
            id: uniformSalesItemRequest.id,
            date: uniformSalesItemRequest.date,
            quantity: uniformSalesItemRequest.quantity,
            paidInFull: uniformSalesItemRequest.paidInFull,
            received: uniformSalesItemRequest.received,
            revenue: uniformSalesItemRequest.revenue,
            studentId: uniformSalesItemRequest.studentId,
            inventoryItemId: uniformSalesItemRequest.inventoryItemId,
            inventoryItem: uniformSalesItemRequest.inventoryItem,
            student: uniformSalesItemRequest.student,
        };
    },

    mapUniformSalesItemToUniformSalesItemRequest: (uniformSalesItemToMap: any) : UniformSalesItemRequest => {


            return {
                id: uniformSalesItemToMap.id,
                date: uniformSalesItemToMap.date,
                quantity: uniformSalesItemToMap.quantity,
                paidInFull: uniformSalesItemToMap.paidInFull,
                received: uniformSalesItemToMap.received,
                revenue: uniformSalesItemToMap.revenue,
                studentId: uniformSalesItemToMap.studentId,
                inventoryItemId: uniformSalesItemToMap.inventoryItemId,
                updatedBy: uniformSalesItemToMap.updatedBy,
                inventoryItem: uniformSalesItemToMap.inventoryItem,
                student: uniformSalesItemToMap.student,
                receipt: uniformSalesItemToMap.receipt
            };
        }

    };