import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getSystemPrivileges } from '../../functions/fetchEntities';
import { RequestAccessToken, createSystemUser, deleteSystemUser, fetchUser, updateSystemUser } from '../../functions/fetchGraph';
import { SystemUser } from '../../types/SystemUser';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

function EditSystemUser() {

const state = useSelector((state: RootState) => state.msalInstance);
const msalInstance = state.msalInstance;

const [validated, setValidated] = useState(false);
const [systemPriviligeLevels, setSystemPriviligeLevels] = useState([] as string[]);
const [systemUser, setSystemUser] = useState({} as SystemUser);
const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

useEffect(() => {

    const loadPage = async () => {
        
        if(parsedId !== "new"){
            const accessToken = await RequestAccessToken(msalInstance);
            const user = await fetchUser(parsedId, accessToken);
            setSystemUser(user);
        }

        const systemPriviligeLevels = await getSystemPrivileges();
        setSystemPriviligeLevels(systemPriviligeLevels);
    }

    loadPage();

        
}, [parsedId, msalInstance]);

 
    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        setSystemUser({...systemUser, mail: email})
    }

    const handlePriviligeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const level = event.target.value;
        setSystemUser({...systemUser, jobTitle: level});
    }


    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{

            const accessToken = await RequestAccessToken(msalInstance);

            if(parsedId === "new"){
                await createSystemUser(accessToken, systemUser);
            }else{

                await updateSystemUser(parsedId, accessToken, systemUser);
            }
            navigate('/Users', {replace: true});
        }
    
        setValidated(true);
      };

    const handleDelete = async (event:any) => {
        event.preventDefault();

        const accessToken = await RequestAccessToken(msalInstance);

        await deleteSystemUser(parsedId, accessToken);

        navigate('/Users', {replace: true});
    };

  
    return (
        <>
             <h1 className='page-title'>Edit System User</h1>
    
            <div className='form-background'>
            <div className='form-container'>
                <Form noValidate validated={validated}>

                {
                    parsedId === "new" ? 
                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="Email" 
                        onChange={handleEmailChange} 
                        value={systemUser?.mail} 
                        required
                        />
                    </Form.Group> :
                    <div>
                        <br />
                        <h2>
                         {systemUser?.mail} 
                        </h2>
                        <br />
                    </div>
                }

                
 
                <Form.Group className="mb-3" controlId="formPrivilege">
                    <Form.Label>Privilege</Form.Label>
                    <select className="form-select" aria-label="privilege" onChange={handlePriviligeChange} required>
                        <option value="">Select a privilege</option>
                        {systemPriviligeLevels.map((level) => (
                            <option key={level} value={level} selected={systemUser?.jobTitle === level}>{level}</option>
                        ))}
                    </select>
                </Form.Group>

                <Button className='edit-form-submit' variant="primary" type="submit" onClick={event => handleSubmit(event)}>
                    Save
                </Button>

                {parsedId !== "new" &&  
                    <Button className='edit-form-submit' variant="primary" onClick={e => handleDelete(e)} >
                        Delete
                    </Button>
                }
                </Form>
            </div>
            </div>
        </>
  )
}

export default EditSystemUser;