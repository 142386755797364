import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getExpenseType, postExpenseType } from '../../functions/fetchEntities';
import Loading from '../Loading';
import { ExpenseTypeRequest } from '../../types/RequestModels/ExpenseTypeRequest';
import { ExpenseTypeMapper } from '../../mappers/ExpenseTypeMapper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

function EditExpenseType() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [selectedExpenseType, setExpenseType] = useState({} as ExpenseTypeRequest);
const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        getExpenseType(parsedId)
            .then((data) => setExpenseType(ExpenseTypeMapper.mapExpenseTypeToExpenseTypeRequest(data)));
    }
        
    }, [parsedId]);

 
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setExpenseType({...selectedExpenseType, name: name});
        setHasBeenEdited(true);
    }


    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            if (hasBeenEdited) {
              selectedExpenseType.updatedBy = systemUser.displayName;
              await postExpenseType(selectedExpenseType);
              navigate('/Expenses', {replace: true});
            }
        }
    
        setValidated(true);
      };

  
    return (
        <>
             {
                parsedId === "new" || selectedExpenseType.id ?
                <div>
                <h1>Edit Expense Type</h1>
        
                <div className='form-background'>
                <div className='form-container'>
                    <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>


                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control 
                        id="edit-name"
                        type="text" 
                        placeholder="Expense Type Name" 
                        onChange={handleNameChange} 
                        value={selectedExpenseType.name} 
                        required
                        />
                    </Form.Group>


                    <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                        Save
                    </Button>
                    </Form>
                </div>
                </div>
             </div> : <Loading />
             }
        </>
  )
}

export default EditExpenseType;