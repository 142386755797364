import { ExamFeePaymentRequest } from "../types/RequestModels/ExamFeePaymentRequest";
import { TermPayment } from "../types/TermFeePayment";


export const ExamFeePaymentMapper = {

    mapExamFeePaymentRequestToExamFeePayment: (examFeePaymentRequest: ExamFeePaymentRequest) : TermPayment => {
        return {
            id: examFeePaymentRequest.id,
            paymentDate: examFeePaymentRequest.paymentDate,
            termId: examFeePaymentRequest.termId,
            studentId: examFeePaymentRequest.studentId,
            amountPaid: examFeePaymentRequest.amountPaid,
            receipt: examFeePaymentRequest.receipt,
            notes: examFeePaymentRequest.notes,
            student: examFeePaymentRequest.student,
            term: examFeePaymentRequest.term
        };
    },

    mapExamFeePaymentToTermFeePaymentRequest: (examFeePaymentToMap: any) : ExamFeePaymentRequest => {
                
            return {
                id: examFeePaymentToMap.id,
                paymentDate: examFeePaymentToMap.paymentDate,
                termId: examFeePaymentToMap.termId,
                studentId: examFeePaymentToMap.studentId,
                amountPaid: examFeePaymentToMap.amountPaid,
                receipt: examFeePaymentToMap.receipt,
                notes: examFeePaymentToMap.notes,
                updatedBy: examFeePaymentToMap.updatedBy,
                student: examFeePaymentToMap.student,
                term: examFeePaymentToMap.term,
            };
        }


};


