import { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getClasses, getPandCUniformSale, getStudenstByClassId, postPandCUniformSale } from '../../../functions/fetchEntities';
import { disableTyping, getCalendarDate, maxDate, minDate } from '../../../helpers/DateHelper';
import { Class } from '../../../types/Class';
import { Student } from '../../../types/Student';
import Loading from '../../Loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { PandCUniformSalesItemRequest } from '../../../types/RequestModels/PandCUniformSalesItemRequest';
import { PandCUniformSalesItemMapper } from '../../../mappers/PandCUniformSalesItemMapper';
import ReceiptComponent from '../../ReceiptComponent';

function EditPandCUniformSalesPage() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [uniformSale, setUniformSale] = useState({datePaid: new Date()} as PandCUniformSalesItemRequest);
const [classes, setClasses] = useState([] as Class[]);
const [students, setStudents] = useState([] as Student[]);
const navigate = useNavigate();


const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  
  useEffect(() => {

    const getProperties = async () => {
        const uniformSale = await getPandCUniformSale(parsedId);
        setUniformSale(PandCUniformSalesItemMapper.mapUniformSalesItemToUniformSalesItemRequest(uniformSale));

        getStudenstByClassId(uniformSale.student.classId)
            .then((data) => setStudents(data));

    }

    if (parsedId !== "new") {

        getProperties();        
    }

    getClasses()
        .then((data) => setClasses(data));
        
    }, [parsedId]);

    const handleDatePaidChange = (event: any) => {
        const date = new Date(event.target.value);

        setUniformSale({...uniformSale, datePaid: date});
        setHasBeenEdited(true);
    }

    const handleDateReceivedChange = (event: any) => {
        const date = new Date(event.target.value);

        setUniformSale({...uniformSale, dateReceived: date});
        setHasBeenEdited(true);
    }

    const handleCheckYardsChange = (event: any) => {
        const checkYards = event.target.value;
        setUniformSale({...uniformSale, checkYards: checkYards});
        setHasBeenEdited(true);
    }

    const handlePinkYardsChange = (event: any) => {
        const pinkYards = event.target.value;
        setUniformSale({...uniformSale, pinkYards: pinkYards});
        setHasBeenEdited(true);
    }

    const handlePaidInFullChange = (event: any) => {
        const paidInFull = (event.target.value === "true");
        setUniformSale({...uniformSale, paidInFull: paidInFull});
        setHasBeenEdited(true);
    }

    const handleReceivedChange = (event: any) => {
        const received = (event.target.value === "true");
        setUniformSale({...uniformSale, received: received});
        setHasBeenEdited(true);
    }

    const handleSeamstressPaidChange = (event: any) => {
        const paid = (event.target.value === "true");
        setUniformSale({...uniformSale, seamstressPaid: paid});
        setHasBeenEdited(true);
    }

    const handleClassChange = (event: any) => {
        const classId = event.target.value;

        getStudenstByClassId(classId)
            .then((data) => setStudents(data));
    }

    const handleStudentChange = (event: any) => {
        const studentId = event.target.value;

        setUniformSale({...uniformSale, studentId: studentId});
        setHasBeenEdited(true);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files;
    
        if(file){
            let reader = new FileReader(); 
            reader.readAsDataURL(file[0]);
    
            reader.onload = () => {

                setUniformSale({...uniformSale, receipt: reader.result});
                setHasBeenEdited(true);
            }
        }
    }

    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{

            if (hasBeenEdited) {
                uniformSale.updatedBy = systemUser.displayName;

                await postPandCUniformSale(uniformSale);
                navigate('/PandCUniformSales', {replace: true});
            }
        }
    
        setValidated(true);
      };

  
    return (
        <>
            { parsedId === "new"  || uniformSale.id ?
             <div>
                <h1>Edit Uniform Sale</h1>
        
                <div className='form-background'>
                <div className='form-container'>
                    <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>
                    <Form.Group className="mb-3" controlId="formDatePaid">
                        <Form.Label>Date Paid</Form.Label>
                        <Form.Control 
                        type="date" 
                        placeholder="Date" 
                        value={getCalendarDate(uniformSale.datePaid)} 
                        onChange={handleDatePaidChange} 
                        onKeyDown={(e) => disableTyping(e)}
                        min={minDate}
                        max={maxDate}
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formDateReceived">
                        <Form.Label>Date Received</Form.Label>
                        <Form.Control 
                        type="date" 
                        placeholder="Date" 
                        value={getCalendarDate(uniformSale.dateReceived)} 
                        onChange={handleDateReceivedChange} 
                        onKeyDown={(e) => disableTyping(e)}
                        min={minDate}
                        max={maxDate}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formClasses">
                        <Form.Label>Class</Form.Label>
                        <select className="form-select" aria-label="Classes" onChange={handleClassChange} required>
                            <option value="">Select a class</option>
                            {classes.map((classItem) => (
                                <option key={classItem.id} 
                                value={classItem.id} 
                                selected={uniformSale.student?.classId === classItem.id}
                                >
                                    {classItem.name}
                                </option>
                            ))}
                        </select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formStudent">
                        <Form.Label>Student</Form.Label>
                        <select className="form-select" aria-label="Students" onChange={handleStudentChange} required>
                            <option value="">Select a student</option>
                            {students.map((student) => (
                                <option key={student.id} 
                                value={student.id} 
                                selected={uniformSale.student?.id === student.id}
                                >
                                    {student.name}
                                </option>
                            ))}
                        </select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formCheckYards">
                        <Form.Label>Check Yards</Form.Label>
                        <Form.Control 
                        type="number" 
                        placeholder="Check Yards" 
                        onChange={handleCheckYardsChange} 
                        value={uniformSale.checkYards} 
                        step="any"
                        min="0.1"
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPinkYards">
                        <Form.Label>Pink Yards</Form.Label>
                        <Form.Control 
                        type="number" 
                        placeholder="Pink Yards" 
                        onChange={handlePinkYardsChange} 
                        value={uniformSale.pinkYards} 
                        step="any"
                        min="0.1"
                        required
                        />
                    </Form.Group>

                    {
                        (uniformSale.receipt === null || uniformSale.receipt === undefined) &&
                        <Form.Group className="mb-3">
                        <Form.Label>Receipt</Form.Label>
                            <Form.Control 
                            type="file"
                            onChange={handleFileChange} 
                            accept='image/*'
                            />
                        </Form.Group>
                    }

                    <Form.Group className="mb-3" controlId="formPaidInFull">
                        <Form.Label>Paid in full</Form.Label>
                        <Form.Check  label="true" value="true" name="paidInFull" type='radio' onChange={handlePaidInFullChange} checked={uniformSale.paidInFull} required/>
                        <Form.Check  label="false" value="false" name="paidInFull" type='radio' onChange={handlePaidInFullChange} checked={!uniformSale.paidInFull} required />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formReceived">
                        <Form.Label>Received</Form.Label>
                        <Form.Check  label="true" value="true" name="received" type='radio' onChange={handleReceivedChange} checked={uniformSale.received} required/>
                        <Form.Check  label="false" value="false" name="received" type='radio' onChange={handleReceivedChange} checked={!uniformSale.received} required />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formSeamstressPaid">
                        <Form.Label>Seamstress Paid</Form.Label>
                        <Form.Check  label="true" value="true" name="seamstressPaid" type='radio' onChange={handleSeamstressPaidChange} checked={uniformSale.seamstressPaid} required/>
                        <Form.Check  label="false" value="false" name="seamstressPaid" type='radio' onChange={handleSeamstressPaidChange} checked={!uniformSale.seamstressPaid} required />
                    </Form.Group>

                    <Button className='edit-form-submit' variant="primary" type="submit">
                        Save
                    </Button>
                    </Form>
                </div>

                <ReceiptComponent receipt={uniformSale.receipt?.toString()} returnUrl='/PandCUniformSales'  />

                </div>
            </div> : <Loading />}
        </>
  )
}

export default EditPandCUniformSalesPage;