import { TermPeriod } from "../types/TermYear";

export const getCurrentTerm = (term: TermPeriod[]) => {

    const today = new Date();

    // if today falls within the start and end date of the term, return the term name
    for (let i = 0; i < term.length; i++) {
        const termStartDate = new Date(term[i].startDate);
        const termEndDate = new Date(term[i].endDate);

        if (today >= termStartDate && today <= termEndDate) {
            return term[i].termName;
        }
    }

    return "Term 1";
}