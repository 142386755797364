import { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getBookItemTypes, getBookSale, postBookSale } from '../../functions/fetchEntities';
import { disableTyping, getCalendarDate, maxDate, minDate } from '../../helpers/DateHelper';
import { InventoryItem } from '../../types/InventoryItem';
import Loading from '../Loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { BookSaleItemRequest } from '../../types/RequestModels/BookSaleItemRequest';
import { BookSaleItemRequestMapper } from '../../mappers/BookSaleItemMapper';

function EditBookSalesPage() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [bookSale, setBookSale] = useState({date: new Date()} as BookSaleItemRequest);
const [bookItemTypes, setBookItemTypes] = useState([] as InventoryItem[]);
const navigate = useNavigate();


const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  
  useEffect(() => {

    if (parsedId !== "new") {
        getBookSale(parsedId)
            .then((data) => setBookSale(BookSaleItemRequestMapper.mapBookSaleItemToBookSaleItemRequest(data)));
    }

    getBookItemTypes()
        .then((data) => setBookItemTypes(data));
        
    }, [parsedId]);

    const handleDateChange = (event: any) => {
        const date = new Date(event.target.value);

        setBookSale({...bookSale, date: date});
        setHasBeenEdited(true);
    }

    const handleNoOfBooksSoldChange = (event: any) => {
        const numberOfBooksSold = event.target.value;

        const bookType = bookItemTypes.find((bookType) => bookType.id === bookSale.inventoryItemId);


        if (!bookType){
            setBookSale({...bookSale, noOfBooksSold: numberOfBooksSold});
        }else{

            const totalAvailable = bookType.quantity as number;

            if (numberOfBooksSold > totalAvailable) {
                alert(`There are only ${totalAvailable} books available`);

                setBookSale({...bookSale, noOfBooksSold: 0});

            }else{
                setBookSale({...bookSale, noOfBooksSold: numberOfBooksSold});
            }
        }

        setHasBeenEdited(true);
    }

    const handleBookTypeChange = (event: any) => {
        const bookTypeId = event.target.value;
        setBookSale({...bookSale, inventoryItemId: bookTypeId});
        setHasBeenEdited(true);
    }

    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            if (hasBeenEdited) {

                const bookType = bookItemTypes.find((bookType) => bookType.id === bookSale.inventoryItemId);

                const totalAvailable = bookType?.quantity as number;

                if (bookSale.noOfBooksSold > totalAvailable) {
                alert(`There are only ${totalAvailable} books available`);

                setBookSale({...bookSale, noOfBooksSold: 0});

                }else{
                    bookSale.updatedBy = systemUser.displayName;

                    await postBookSale(bookSale);
                    navigate('/BookSales', {replace: true});
                }
            }
        }
    
        setValidated(true);
      };

  
    return (
        <>
             {parsedId === "new" || bookSale.id ? <div>
                <h1>Edit Book Sale</h1>
        
                <div className='form-background'>
                    <div className='form-container'>
                        <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>
                        <Form.Group className="mb-3" controlId="formDate">
                            <Form.Label>Date</Form.Label>
                            <Form.Control 
                            type="date" 
                            placeholder="Date" 
                            value={getCalendarDate(bookSale.date)} 
                            onChange={handleDateChange} 
                            onKeyDown={(e) => disableTyping(e)}
                            min={minDate}
                            max={maxDate}
                            required
                            />
                        </Form.Group>

                        {parsedId === "new" && 
                            <Form.Group className="mb-3" controlId="formBookType">
                            <Form.Label>Book Type</Form.Label>
                            <select className="form-select" aria-label="Book Type" onChange={handleBookTypeChange} required>
                                <option value="">Select a book type</option>
                                {bookItemTypes.map((bookType) => (
                                    <option key={bookType.id} 
                                    value={bookType.id} 
                                    selected={bookSale.inventoryItemId === bookType.id}
                                    disabled={bookType.quantity === 0}
                                    >
                                        {bookType.name}{bookType.quantity === 0 ? " (Out of stock)" : ""}
                                    </option>
                                ))}
                            </select>
                        </Form.Group>
                    }

                        <Form.Group className="mb-3" controlId="formNoOfBooksSold">
                            <Form.Label>Number of books sold</Form.Label>
                            <Form.Control 
                            type="number"
                            placeholder="No Of Books Sold" 
                            onChange={handleNoOfBooksSoldChange} 
                            value={bookSale.noOfBooksSold} 
                            min="1"
                            required
                            />
                        </Form.Group>


                        <Button className='edit-form-submit' variant="primary" type="submit">
                            Save
                        </Button>
                        </Form>
                    </div>
                </div>
             </div> : <Loading />}
        </>
  )
}

export default EditBookSalesPage;