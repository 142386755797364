import React from 'react'

function Loading() {
  return (
    <div className='loading-container'>
      <div className="loader"></div> 
    </div>
  )
}

export default Loading