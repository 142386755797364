import { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getClassFee, postClassFee } from '../../functions/fetchEntities';
import Loading from '../Loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { ExtraClassFeeItemRequest } from '../../types/RequestModels/ExtraClassFeeItemRequest';
import { ExtraClassFeeItemMapper } from '../../mappers/ExtraClassFeeItemMapper';
import { disableTyping, getCalendarDate, maxDate, minDate } from '../../helpers/DateHelper';

function EditClassFeesPage() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [classFee, setClassFees] = useState({date: new Date()} as ExtraClassFeeItemRequest);
const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  
  useEffect(() => {

    if (parsedId !== "new") {
        getClassFee(parsedId)
            .then((data) => setClassFees(ExtraClassFeeItemMapper.mapClassFeeItemToClassRequest(data)));
    }

    }, [parsedId]);

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = new Date(event.target.value);
        setClassFees({...classFee, date: date});


        setHasBeenEdited(true);
    }

    const handleNumberEnrolledChange = (event: any) => {
        const numberEnrolled = event.target.value;
        setClassFees({...classFee, numberEnrolled: numberEnrolled});
        setHasBeenEdited(true);
    }

    const handleNumberPaidChange = (event: any) => {
        const numberPaid = event.target.value;
        setClassFees({...classFee, numberPaid: numberPaid});
        setHasBeenEdited(true);
    }

    const handleAmountReceivedChange = (event: any) => {
        const amountReceived = event.target.value;
        setClassFees({...classFee, amountReceived: amountReceived});
        setHasBeenEdited(true);
    }

    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            if (hasBeenEdited) {
                classFee.updatedBy = systemUser.displayName;
                await postClassFee(classFee);
                navigate('/ExtraClasses', {replace: true});
            }
        }
    
        setValidated(true);
      };

  
    return (
        <>
             {parsedId === "new" || classFee.id ? <div>
                <h1>Edit Extra Class Fee</h1>
        
                    <div className='form-background'>
                        <div className='form-container'>
                            <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                            <Form.Group className="mb-3" controlId="formDate">
                                <Form.Label>Date</Form.Label>
                                <Form.Control 
                                type="date" 
                                placeholder="Date" 
                                value={getCalendarDate(classFee.date)} 
                                onChange={handleDateChange} 
                                onKeyDown={(e) => disableTyping(e)}
                                min={minDate}
                                max={maxDate}
                                required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Number Enrolled</Form.Label>
                                <Form.Control 
                                id="edit-enrolled"
                                type="number" 
                                placeholder="Number Enrolled" 
                                onChange={handleNumberEnrolledChange} 
                                value={classFee.numberEnrolled} 
                                min="1"
                                required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Number Paid</Form.Label>
                                <Form.Control 
                                id="edit-paid"
                                type="number" 
                                placeholder="Number Paid" 
                                onChange={handleNumberPaidChange} 
                                value={classFee.numberPaid} 
                                min="0.1"
                                step="any"
                                required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Amount Received</Form.Label>
                                <Form.Control 
                                id="edit-received"
                                type="number"
                                step="any" 
                                placeholder="Amount Received" 
                                onChange={handleAmountReceivedChange} 
                                value={classFee.amountReceived} 
                                min="0.1"
                                required
                                />
                            </Form.Group>

                            <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                                Save
                            </Button>
                            </Form>
                        </div>
                    </div>
                </div> : <Loading />
             }
        </>
  )
}

export default EditClassFeesPage;